import images from './Shared/assets/images';

export const ENABLE_REDUX_LOGGER = false;

export const loginVerificationFields = [
  'token',
  'rememberMe',
  'userRef',
  'role',
  'language'
];

export const userActions = {
  addUser: 'ADD_USER',
  editUser: 'EDIT_USER',
  inviteUser: 'INVITE_USER',
  addCompany: 'ADD_COMPANY'
};

export const userRoles = {
  annotator: 'annotator',
  super: 'super',
  manager: 'manager',
  executive: 'executive',
  admin: 'admin'
};

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK'
};

export const labelColors = {
  uploading: 'primary',
  completed: 'success',
  pending: 'warning',
  error: 'error'
};

export const dateFilterOptions = [
  {
    value: 0,
    label: 'DATE_FILTER_OPTION_ALL'
  },
  {
    value: 1,
    label: 'DATE_FILTER_OPTION_TODAY'
  },
  {
    value: 2,
    label: 'DATE_FILTER_OPTION_YESTERDAY'
  },
  {
    value: 3,
    label: 'DATE_FILTER_OPTION_LAST_WEEK'
  },
  {
    value: 4,
    label: 'DATE_FILTER_OPTION_LAST_MONTH'
  }
];

export const recentFilterOptions = [
  {
    value: 100,
    label: 'DASHBOARD_INVOICES_PERIOD_ALL'
  },
  {
    value: 1,
    label: 'DASHBOARD_INVOICES_PERIOD_THIS_WEEK'
  },
  {
    value: 2,
    label: 'DASHBOARD_INVOICES_PERIOD_LAST_WEEK'
  },
  {
    value: 3,
    label: 'DASHBOARD_INVOICES_PERIOD_THIS_MONTH'
  },
  {
    value: 4,
    label: 'DASHBOARD_INVOICES_PERIOD_LAST_MONTH'
  },
  {
    value: 5,
    label: 'DASHBOARD_INVOICES_PERIOD_THIS_QUARTER'
  },
  {
    value: 6,
    label: 'DASHBOARD_INVOICES_PERIOD_LAST_QUARTER'
  },
  {
    value: 7,
    label: 'DASHBOARD_INVOICES_PERIOD_THIS_YEAR'
  },
  {
    value: 8,
    label: 'DASHBOARD_INVOICES_PERIOD_LAST_YEAR'
  }
];

export const filterTimePeriod = [
  {
    index: 100,
    value: 'all'
  },
  {
    index: 1,
    value: 'tweek'
  },
  {
    index: 2,
    value: 'lweek'
  },
  {
    index: 3,
    value: 'tmonth'
  },
  {
    index: 4,
    value: 'lmonth'
  },
  {
    index: 5,
    value: 'tquater'
  },
  {
    index: 6,
    value: 'lquater'
  },
  {
    index: 7,
    value: 'tyear'
  },
  {
    index: 8,
    value: 'lyear'
  },
  {
    index: 99,
    value: 'custom'
  }
];

export const timelyFilterOptions = [
  {
    value: 0,
    label: 'TIME_FILTER_OPTION_DAILY'
  },
  {
    value: 1,
    label: 'TIME_FILTER_OPTION_WEEKLY'
  },
  {
    value: 2,
    label: 'TIME_FILTER_OPTION_MONTHLY'
  },
  {
    value: 3,
    label: 'TIME_FILTER_OPTION_QUARTERLY'
  },
  {
    value: 4,
    label: 'TIME_FILTER_OPTION_ANNUALLY'
  }
];

export const invoiceFilterOptions = [
  {
    value: 0,
    label: 'INVOICE_FILTER_OPTION_DIGITIZED'
  },
  {
    value: 1,
    label: 'INVOICE_FILTER_OPTION_UPLOADING'
  },
  {
    value: 6,
    label: 'INVOICE_FILTER_OPTION_ERRORED'
  }
];

export const invoiceFilterOptionsNew = [
  {
    value: 0,
    label: 'INVOICE_FILTER_OPTION_DIGITIZED'
  },
  {
    value: 1,
    label: 'INVOICE_FILTER_OPTION_UPLOADING'
  },
  {
    value: 2,
    label: 'INVOICE_FILTER_OPTION_TO_REVIEW'
  },
  {
    value: 3,
    label: 'INVOICE_FILTER_OPTION_TO_VALIDATE'
  },
  {
    value: 4,
    label: 'INVOICE_FILTER_OPTION_TO_EXPORT'
  },
  {
    value: 5,
    label: 'INVOICE_FILTER_OPTION_EXPORTED'
  },
  {
    value: 6,
    label: 'INVOICE_FILTER_OPTION_ERRORED'
  },
  {
    value: 7,
    label: 'INVOICE_FILTER_OPTION_REJECTED'
  }
];

export const typeFilterOptions = [
  {
    value: 'All',
    label: 'TYPE_FILTER_OPTION_ALL'
  },
  {
    value: 'Invoice',
    label: 'TYPE_FILTER_OPTION_INVOICES'
  },
  {
    value: 'Purchase Order',
    label: 'TYPE_FILTER_OPTION_PO'
  }
];

export const userTypeFilterOptions = [
  {
    value: 'All',
    label: 'USER_TYPE_FILTER_OPTION_ALL'
  },
  {
    value: 'Active',
    label: 'USER_TYPE_FILTER_OPTION_ACTIVE'
  },
  {
    value: 'Inactive',
    label: 'USER_TYPE_FILTER_OPTION_INACTIVE'
  }
];

export const invStatusFilterOptions = [
  {
    value: 'All',
    label: 'INV_STATUS_FILTER_OPTION_ALL'
  },
  {
    value: 'uploading',
    label: 'INV_STATUS_FILTER_OPTION_UPLOADING'
  },
  {
    value: 'pending',
    label: 'INV_STATUS_FILTER_OPTION_PENDING'
  },
  {
    value: 'completed',
    label: 'INV_STATUS_FILTER_OPTION_COMPLETED'
  },
  {
    value: 'error',
    label: 'INV_STATUS_FILTER_OPTION_REJECTED'
  }
];

export const statusFilterOptions = [
  {
    value: 'All',
    label: 'STATUS_FILTER_OPTION_ALL'
  },
  {
    value: 'Active',
    label: 'STATUS_FILTER_OPTION_ACTIVE'
  },
  {
    value: 'Inactive',
    label: 'STATUS_FILTER_OPTION_INACTIVE'
  }
];

export const invoiceStatuses = {
  all: 'INVOICE_STATUS_ALL',
  uploading: 'INVOICE_STATUS_UPLOADING',
  digitized: 'INVOICE_STATUS_DIGITIZED',
  toreview: 'INVOICE_STATUS_TO_REVIEW',
  reviewed: 'INVOICE_STATUS_TO_REVIEWED',
  tovalidate: 'INVOICE_STATUS_TO_VALIDATE',
  toexport: 'INVOICE_STATUS_TO_EXPORT',
  exported: 'INVOICE_STATUS_EXPORTED',
  rejected: 'INVOICE_STATUS_REJECTED',
  archived: 'INVOICE_STATUS_ARCHIVED',
  outOfLimit: 'INVOICE_STATUS_OUT_OF_LIMIT',
  deleted: 'INVOICE_STATUS_DELETED',
  errored: 'INVOICE_STATUS_ERRORED',
  splitRequest: 'INVOICE_STATUS_SPLIT_REQUEST',
  possibleDuplicate: 'INVOICE_POSSIBLE_DUPLICATE'
};

export const invoiceStatusesForDropdown = {
  all: 'INVOICE_STATUS_ALL',
  uploading: 'INVOICE_STATUS_UPLOADING',
  digitized: 'INVOICE_STATUS_DIGITIZED',
  toreview: 'INVOICE_STATUS_TO_REVIEW',
  tovalidate: 'Send to validate',
  toexport: 'INVOICE_STATUS_TO_EXPORT',
  exported: 'INVOICE_STATUS_EXPORTED',
  rejected: 'INVOICE_STATUS_REJECTED',
  archived: 'INVOICE_STATUS_ARCHIVED',
  outOfLimit: 'INVOICE_STATUS_OUT_OF_LIMIT',
  deleted: 'INVOICE_STATUS_DELETED',
  errored: 'INVOICE_STATUS_ERRORED',
  possibleDuplicate: 'INVOICE_POSSIBLE_DUPLICATE'
};

export const invoiceStatusesKeys = {
  all: 'all',
  uploading: 'uploading',
  digitized: 'digitized',
  toreview: 'toreview',
  reviewed: 'reviewed',
  tovalidate: 'tovalidate',
  toexport: 'toexport',
  exported: 'exported',
  rejected: 'rejected',
  archived: 'archived',
  duplicated: 'duplicated',
  outOfLimit: 'outoflimit',
  errored: 'errored',
  splitRequest: 'splitRequest',
  possibleDuplicate: 'possibleDuplicate'
};

export const invoiceStatusCode = {
  digitized: 16,
  toreview: 500,
  reviewed: 509,
  tovalidate: 501,
  toexport: 502,
  exported: 503,
  rejected: 510,
  archived: 600,
  outOfLimit: 999,
  errored: 100
};

export const documentTypes = {
  invoice: 'INVOICE',
  creditNote: 'CREDIT_INVOICE',
  attachment: 'ATTACHMENT',
  purchaseOrder: 'PURCHASE_ORDER',
  receipt: 'RECEIPT',
  passportOrId: 'PASSPORT_OR_ID'
};

export const secondaryDocumentTypes = {
  invoice: 'INVOICE',
  order: 'ORDER',
  deliveryNote: 'DELIVERY_NOTE',
  cancellation: 'CANCELLATION',
  creditNote: 'CREIDT_NOTE',
  debitNote: 'DEBIT_NOTE',
};

export const invoiceStatusCodesList = [500, 501, 502, 503, 510, 600];

export const userEditOptions = [
  'change',
  'resend',
  'revoke',
  'remove',
  'restore',
  'delete'
];

export const exportFormatTypes = [
  {
    id: 0,
    key: 'ubl23',
    label: 'UBL - 2.3',
    exportViaApp: true
  },
  {
    id: 1,
    key: 'ubl21',
    label: 'UBL - 2.1',
    exportViaApp: true
  },
  {
    id: 2,
    key: 'csv',
    label: 'CSV',
    exportViaApp: true
  },
  {
    id: 3,
    key: 'ubl23_csv',
    label: 'UBL, CSV',
    exportViaApp: false
  }
];

export const viewTypes = {
  grid: 'grid',
  jira: 'jira'
};

export const supplierFields = [
  {
    title: 'SUPPLIERS_GENERAL',
    fields: [
      {
        key: 'creditorCode',
        label: 'SUPPLIERS_CREDITOR_CODE',
        required: true
      },
      {
        key: 'creditorName',
        label: 'SUPPLIERS_CREDITOR_NAME',
        required: true
      },
      {
        key: 'autoPaymentStatus',
        label: 'SUPPLIERS_AUTO_PAYMENT_STATUS',
        required: false
      },
      {
        key: 'dueDays',
        label: 'SUPPLIERS_DUE_DAYS',
        required: false
      },
      {
        key: 'website',
        label: 'SUPPLIERS_WEBSITE',
        required: false
      },
      // {
      //   key: 'comment',
      //   label: 'SUPPLIERS_COMMENT',
      //   required: false
      // },
      {
        key: 'companyRegNumber',
        label: 'SUPPLIERS_COMPANY_REGISTRATION_NUMBER',
        required: false
      },
      {
        key: 'vatNumber',
        label: 'SUPPLIERS_VAT_NUMBER',
        required: false
      },
      {
        key: 'referenceCode',
        label: 'SUPPLIERS_REFERENCE_CODE',
        required: false
      },
      {
        key: 'status',
        label: 'SUPPLIERS_STATUS',
        required: false
      },
      {
        key: 'generalLedgerAccount',
        label: 'SUPPLIERS_GENERAL_LEDGER_ACCOUNT',
        required: false,
        notCommonField: true
      },
      {
        key: 'vatGroup',
        label: 'SUPPLIERS_VAT_GROUP',
        required: false
      },
      {
        key: 'invoiceDescriptionRule',
        label: 'SUPPLIERS_INVOICE_DESCRIPTION_RULE',
        required: false
      }
    ]
  },
  {
    title: 'SUPPLIERS_PERSONAL_INVOICE_SETTINGS',
    fields: [
      {
        key: 'invoiceLineDescriptionRule',
        label: 'SUPPLIERS_INVOICE_LINE_DESCRIPTION_RULE',
        required: false
      },
      {
        key: 'defaultCurrency',
        label: 'SUPPLIERS_DEFAULT_CURRENCY',
        required: false
      },
      {
        key: 'generalLedgerAccLine',
        label: 'SUPPLIERS_GENERAL_LEDGER_ACCOUNT',
        required: false
      },
      {
        key: 'vatGroupLine',
        label: 'SUPPLIERS_VAT_GROUP_LINE',
        required: false
      },
      {
        key: 'costCenter',
        label: 'SUPPLIERS_COST_CENTER',
        required: false
      },
      {
        key: 'project',
        label: 'SUPPLIERS_PROJECT',
        required: false
      }
    ]
  },
  {
    title: 'SUPPLIERS_BANK_DETAILS',
    fields: [
      {
        key: 'bankCountry',
        label: 'SUPPLIERS_BANK_COUNTRY',
        required: false
      },
      {
        key: 'ascription',
        label: 'SUPPLIERS_ASCRIPTION',
        required: false
      },
      {
        key: 'iban',
        label: 'SUPPLIERS_IBAN',
        required: false
      },
      {
        key: 'bankAccNumber',
        label: 'SUPPLIERS_BANK_ACCOUNT_NUMBER',
        required: false
      },
      {
        key: 'bicSwift',
        label: 'SUPPLIERS_BIC_SWIFT',
        required: false
      },
      {
        key: 'nationalBankCode',
        label: 'SUPPLIERS_NATIONAL_BANK_CODE',
        required: false
      },
      {
        key: 'bankName',
        label: 'SUPPLIERS_BANK_NAME',
        required: false
      },
      {
        key: 'bankCity',
        label: 'SUPPLIERS_BANK_CITY',
        required: false
      },
      {
        key: 'bankStreet',
        label: 'SUPPLIERS_BANK_STREET',
        required: false
      },
      {
        key: 'bankHouseNumber',
        label: 'SUPPLIERS_BANK_HOUSE_NUMBER',
        required: false
      },
      {
        key: 'postcode',
        label: 'SUPPLIERS_BANK_POSTCODE',
        required: false
      },
      {
        key: 'state',
        label: 'SUPPLIERS_BANK_STATE',
        required: false
      }
    ]
  },
  {
    title: 'SUPPLIERS_SUPPLIER_ADDRESS',
    fields: [
      {
        key: 'country',
        label: 'SUPPLIERS_COUNTRY',
        required: false
      },
      {
        key: 'city',
        label: 'SUPPLIERS_CITY',
        required: false
      },
      {
        key: 'postalCode',
        label: 'SUPPLIERS_POSTAL_CODE',
        required: false
      },
      {
        key: 'address1',
        label: 'SUPPLIERS_ADDRESS_ONE',
        required: false
      },
      {
        key: 'address2',
        label: 'SUPPLIERS_ADDRESS_TWO',
        required: false
        // },
        // {
        //   key: 'poBox',
        //   label: 'SUPPLIERS_PO_BOX',
        //   required: false
        // },
        // {
        //   key: 'poBoxPostalCode',
        //   label: 'SUPPLIERS_PO_BOX_POSTAL_CODE',
        //   required: false
        // },
        // {
        //   key: 'poBoxCity',
        //   label: 'SUPPLIERS_PO_BOX_CITY',
        //   required: false
      }
    ]
  },
  {
    title: 'SUPPLIERS_CONTACT_DATA',
    fields: [
      {
        key: 'contactPersonName',
        label: 'SUPPLIERS_CONTACT_PERSON_NAME',
        required: false
      },
      {
        key: 'telephoneNumber',
        label: 'SUPPLIERS_TELEPHONE_NUMBER',
        required: false
      },
      {
        key: 'faxNumber',
        label: 'SUPPLIERS_FAX_NUMBER',
        required: false
      },
      {
        key: 'mobileTelNumber',
        label: 'SUPPLIERS_MOBILE_TELEPHONE_NUMBER',
        required: false
      },
      {
        key: 'emailAddress',
        label: 'SUPPLIERS_EMAIL_ADDRESS',
        required: false
      }
    ]
  },
  {
    title: 'SUPPLIERS_PAYMENT_CONDITIONS',
    fields: [
      {
        key: 'discountDays',
        label: 'SUPPLIERS_DISCOUNT_DAYS',
        required: false
      },
      {
        key: 'discountPercentage',
        label: 'SUPPLIERS_DISCOUNT_PERCENTAGE',
        required: false
      }
    ]
  }
];

export const supplierData = {
  statuses: ['active', 'deleted'],
  apStatuses: [{
    name: 'SEPANLCT',
    code: 'SEPANLCT',
  },
  {
    name: 'BTL91',
    code: '350NL',
  },
  {
    name: 'No',
    code: 'No',
  }],
  defaultGLA: [{
    code: 'None',
    name: ''
  }],
  defaultVatGroup: [{
    code: 'No VAT',
    name: ''
  }],
  defaultCostCenters: [{
    code: 'None',
    name: ''
  }],
  defaultProjects: [{
    code: 'None',
    name: ''
  }]
};

export const costCenterFields = [
  {
    title: 'COST_CENTER_GENERAL',
    fields: [
      {
        key: 'code',
        label: 'COST_CENTER_CODE',
        required: true
      },
      {
        key: 'name',
        label: 'COST_CENTER_NAME',
        required: true
      }
    ]
  }
];

export const projectFields = [
  {
    title: 'PROJECT_GENERAL',
    fields: [
      {
        key: 'code',
        label: 'PROJECT_CODE',
        required: true
      },
      {
        key: 'name',
        label: 'PROJECT_NAME',
        required: true
      }
    ]
  },
  {
    title: 'PROJECT_COST_CENTER',
    fields: [
      {
        key: 'costCenter',
        label: 'PROJECT_COST_CENTER',
        required: false
      }
    ]
  }
];

export const vatCodeFields = [
  {
    title: 'VAT_CODE_GENERAL',
    fields: [
      {
        key: 'code',
        label: 'VAT_CODE_CODE',
        required: true
      },
      {
        key: 'name',
        label: 'VAT_CODE_NAME',
        required: true
      },
      {
        key: 'type',
        label: 'VAT_CODE_TYPE',
        required: true
      },
      {
        key: 'shortName',
        label: 'VAT_CODE_SHORT_NAME',
        required: false
      },
      {
        key: 'invoiceText',
        label: 'VAT_CODE_INVOICE_TEXT',
        required: false
      }
    ]
  },
  {
    title: 'VAT_CODE_RATE_DETAILS',
    fields: [
      {
        key: 'ratePercentage',
        label: 'VAT_CODE_RATE_PERCENTAGE',
        required: true
      },
      {
        key: 'rateName',
        label: 'VAT_CODE_RATE_NAME',
        required: true
      },
      {
        key: 'rateShortName',
        label: 'VAT_CODE_RATE_SHORT_NAME',
        required: true
      }
    ]
  },
  {
    title: 'VAT_CODE_ALLOCATION_RULES',
    fields: [
      {
        key: 'ruleAccount',
        label: 'VAT_CODE_RULE_ACCOUNT',
        required: true
      },
      {
        key: 'rulePercentage',
        label: 'VAT_CODE_RULE_PERCENTAGE',
        required: true
      },
      {
        key: 'ruleCostCenter',
        label: 'VAT_CODE_RULE_COST_CENTER',
        required: true
      }
      // {
      //   key: 'ruleVatGroup',
      //   label: 'VAT_CODE_RULE_VAT_GROUP',
      //   required: true
      // },
    ]
  }
];

export const glaFields = [
  {
    title: 'GLA_GENERAL',
    fields: [
      {
        key: 'code',
        label: 'GLA_CODE',
        required: true
      },
      {
        key: 'name',
        label: 'GLA_NAME',
        required: true
      },
      {
        key: 'fromYear',
        label: 'GLA_FROM_YEAR',
        required: false
      },
      {
        key: 'fromPeriod',
        label: 'GLA_FROM_PERIOD',
        required: false
      },
      {
        key: 'endYear',
        label: 'GLA_END_YEAR',
        required: false
      },
      {
        key: 'endPeriod',
        label: 'GLA_END_PERIOD',
        required: false
      }
    ]
  },
  {
    title: 'GLA_ELECTRONIC_BANK_STATEMENT',
    fields: [
      {
        key: 'accountNumber',
        label: 'GLA_ACCOUNT_NUMBER',
        required: false
      },
      {
        key: 'accountHolder',
        label: 'GLA_ACCOUNT_HOLDER',
        required: false
      }
    ]
  },
  {
    title: 'GLA_LINKS',
    fields: [
      {
        key: 'furtherAnalysis',
        label: 'GLA_FURTHER_ANALYSIS',
        required: false
      },
      {
        key: 'matchable',
        label: 'GLA_MATCHABLE',
        required: true
      },
      {
        key: 'vat',
        label: 'GLA_VAT',
        required: false
      },
      {
        key: 'vatType',
        label: 'GLA_VAT_TYPE',
        required: false
      },
      {
        key: 'performanceType',
        label: 'GLA_PERFORMANCE_TYPE',
        required: false
      }
    ]
  }
];

export const glaYearsFields = [
  {
    title: 'GLA_YEARS_BEGIN_LIMITS',
    fields: [
      {
        key: 'beginFirst',
        label: 'GLA_YEARS_BEGIN_FIRST',
        required: true
      },
      {
        key: 'beginLast',
        label: 'GLA_YEARS_BEGIN_LAST',
        required: true
      }
    ]
  },
  {
    title: 'GLA_YEARS_END_LIMITS',
    fields: [
      {
        key: 'endFirst',
        label: 'GLA_YEARS_END_FIRST',
        required: true
      },
      {
        key: 'endLast',
        label: 'GLA_YEARS_END_LAST',
        required: true
      }
    ]
  }
];

export const vatCodeTypes = [
  {
    name: 'None',
    label: 'VAT_CODE_TYPE_NONE'
  },
  {
    name: 'sales',
    label: 'VAT_CODE_TYPE_SALES'
  },
  {
    name: 'purchase',
    label: 'VAT_CODE_TYPE_PURCHASE'
  }
];

export const glAccountData = {
  year: [
    {
      code: 'None',
      name: 'GLA_NO_BEGIN_LIMIT'
    }
  ],
  endYear: [
    {
      code: 'None',
      name: 'GLA_NO_END_LIMIT'
    }
  ],
  period: [
    {
      code: '0',
      name: 'GLA_PERIOD_0'
    },
    {
      code: '1',
      name: 'GLA_PERIOD_1'
    },
    {
      code: '2',
      name: 'GLA_PERIOD_2'
    },
    {
      code: '3',
      name: 'GLA_PERIOD_3'
    },
    {
      code: '4',
      name: 'GLA_PERIOD_4'
    },
    {
      code: '5',
      name: 'GLA_PERIOD_5'
    },
    {
      code: '6',
      name: 'GLA_PERIOD_6'
    },
    {
      code: '7',
      name: 'GLA_PERIOD_7'
    },
    {
      code: '8',
      name: 'GLA_PERIOD_8'
    },
    {
      code: '9',
      name: 'GLA_PERIOD_9'
    },
    {
      code: '10',
      name: 'GLA_PERIOD_10'
    },
    {
      code: '11',
      name: 'GLA_PERIOD_11'
    },
    {
      code: '12',
      name: 'GLA_PERIOD_12'
    },
    {
      code: '55',
      name: 'GLA_PERIOD_55'
    },
    {
      code: '56',
      name: 'GLA_PERIOD_56'
    }
  ],
  furtherAnalysis: [
    {
      code: 'yes',
      name: 'GLA_FURTHER_ANALYSIS_YES'
    },
    {
      code: 'nowandthen',
      name: 'GLA_FURTHER_ANALYSIS_NOW_AND_THEN'
    },
    {
      code: 'no',
      name: 'GLA_FURTHER_ANALYSIS_NO'
    }
  ],
  vat: [
    {
      code: 'None',
      name: ''
    }
  ],
  performanceType: [
    {
      code: 'None',
      name: 'GLA_PERFORMANCE_TYPE_NONE'
    },
    {
      code: 'goods',
      name: 'GLA_PERFORMANCE_TYPE_GOODS'
    },
    {
      code: 'services',
      name: 'GLA_PERFORMANCE_TYPE_SERVICES'
    }
  ],
  vatType: [
    {
      code: 'None',
      name: 'GLA_VAT_TYPE_NONE'
    },
    {
      code: 'sales',
      name: 'GLA_VAT_TYPE_SALES'
    },
    {
      code: 'purchase',
      name: 'GLA_VAT_TYPE_PURCHASE'
    }
  ]
};

export const subscriptionTypes = {
  free: {
    level: 0,
    value: 4,
    label: 'Free',
    name: 'Free Plan',
    i18n: 'MY_PLAN_FREE',
    invoices: 20,
    users: 1,
    apiAccess: false,
    deliverViaEmail: true,
    deliverViaIntegrations: false,
    supportQueries: false,
    customeModalTraining: false
  },
  starter: {
    level: 1,
    value: 29,
    label: 'Starter',
    name: 'Starter Plan',
    i18n: 'MY_PLAN_STARTER',
    invoices: 50,
    users: 3,
    price: 999,
    ecLimit: 10,
    ecPrice: 350,
    apiAccess: true,
    deliverViaEmail: true,
    deliverViaIntegrations: true,
    supportQueries: false,
    customeModalTraining: false
  },
  business: {
    level: 2,
    value: 57,
    label: 'Business',
    name: 'Business Plan',
    i18n: 'MY_PLAN_BUSINESS',
    invoices: 200,
    users: 10,
    price: 3499,
    ecLimit: 25,
    ecPrice: 750,
    apiAccess: true,
    deliverViaEmail: true,
    deliverViaIntegrations: true,
    supportQueries: true,
    customeModalTraining: false
  },
  enterprise: {
    level: 3,
    value: 86,
    label: 'Enterprise',
    name: 'Enterprise Plan',
    i18n: 'MY_PLAN_ENTERPRISE',
    invoices: '>200',
    users: '>10',
    price: 0,
    ecLimit: 0,
    ecPrice: 0,
    apiAccess: true,
    deliverViaEmail: true,
    deliverViaIntegrations: true,
    supportQueries: true,
    customeModalTraining: true
  }
};

export const subscriptionTypesAr = [
  {
    value: 4
    // label: 'Free',
  },
  {
    value: 30
    // label: 'Starter',
  },
  {
    value: 58
    // label: 'Business',
  },
  {
    value: 87
    // label: 'Custom',
  }
];

export const nonIdApps = ['mbc', 'tf', 'assistantapi', 'holded', 'odoo', 'sap', 'quickbooks-online'];

export const integrationNames = {
  emailReceiver: 'email',
  api: 'api',
  splitDocument: 'split-document',
  businessCentral: 'mbc',
  twinfield: 'tf',
  emailSender: 'exportemail',
  assistantAPI: 'assistantapi',
  quickbooks: 'quickbooks-online',
  holded: 'holded',
  airtable: 'airtable',
  sftpReceiver: 'sftp-receiver',
  sftpSender: 'sftp-sender',
  onedrive: 'onedrive',
  dropbox: 'dropbox',
  googleDrive: 'googledrive',
  odoo: 'odoo',
  sap: 'sap',
  gstock: 'gstock',
};

export const integrations = [
  {
    id: 0,
    name: integrationNames.emailReceiver,
    title: 'INTEGRATIONS_EMAIL_TITLE',
    description: 'INTEGRATIONS_EMAIL_DESCRIPTION',
    link: 'https://procys.com/help-center-article/email-receiver-forward-your-invoices-to-procys-automatically-via-email',
    byZapier: false,
    available: true,
    free: true,
    img: images.email.default,
    isAllowed: true
  },
  {
    id: 1,
    name: integrationNames.api,
    title: 'INTEGRATIONS_PROCYS_API_TITLE',
    description: 'INTEGRATIONS_PROCYS_API_DESCRIPTION',
    link: 'https://docs.{{BRAND_DOMAIN}}',
    byZapier: false,
    available: true,
    img: images.api,
    isBeta: false,
    isAllowed: true
  },
  {
    id: 2,
    name: integrationNames.splitDocument,
    title: 'INTEGRATIONS_SPLIT_DOCUMENT',
    description: 'INTEGRATIONS_SPLIT_DOCUMENT_DESCRIPTION',
    link: '',
    byZapier: false,
    available: true,
    img: images.splitScreen.default,
    isBeta: true,
    isAllowed: true,
    noManage: true
  },
  {
    id: 3,
    name: integrationNames.businessCentral,
    title: 'INTEGRATIONS_BC_TITLE',
    description: 'INTEGRATIONS_BC_DESCRIPTION',
    link: 'https://procys.com/help-center-article/how-to-connect-business-central-dynamics-365-to-procys',
    byZapier: false,
    available: true,
    img: images.bc.default,
    isAllowed: true
  },
  {
    id: 4,
    name: integrationNames.twinfield,
    title: 'INTEGRATIONS_TF_TITLE',
    description: 'INTEGRATIONS_TF_DESCRIPTION',
    link: 'https://procys.com/help-center-article/how-to-connect-twinfield-to-procys',
    byZapier: false,
    available: true,
    img: images.tf,
    isAllowed: true
  },
  {
    id: 5,
    name: integrationNames.emailSender,
    title: 'INTEGRATIONS_EXPORT_EMAIL_TITLE',
    description: 'INTEGRATIONS_EXPORT_EMAIL_DESCRIPTION',
    link: 'https://procys.com/help-center-article/email-sender-automatically-send-your-processed-data-to-email',
    byZapier: false,
    available: true,
    img: images.email.default,
    isAllowed: true
  },
  {
    id: 6,
    name: integrationNames.assistantAPI,
    title: 'INTEGRATIONS_API_TITLE',
    description: 'INTEGRATIONS_API_DESCRIPTION',
    link: 'https://assistant.procys.com/api-authlogin/?next=/',
    byZapier: false,
    available: true,
    img: images.api,
    isAllowed: true
  },
  // {
  //   id: 6,
  //   name: 'quickbooks',
  //   title: 'INTEGRATIONS_QUICKBOOKS_TITLE',
  //   description: 'INTEGRATIONS_QUICKBOOKS_DESCRIPTION',
  //   link: '',
  //   flowId: 'cEzGfj-lr',
  //   byZapier: false,
  //   available: true,
  //   img: images.qb,
  //   appName: 'quickbooks',
  //   isAllowed: true
  // },
  {
    id: 7,
    name: integrationNames.quickbooks,
    title: 'INTEGRATIONS_QUICKBOOKS_TITLE',
    description: 'INTEGRATIONS_QUICKBOOKS_DESCRIPTION',
    link: '',
    byZapier: false,
    available: true,
    img: images.qb,
    appName: 'quickbooks',
    isAllowed: true
  },
  {
    id: 8,
    name: integrationNames.holded,
    title: 'INTEGRATIONS_HOLDED_TITLE',
    description: 'INTEGRATIONS_HOLDED_DESCRIPTION',
    zapierLink: 'https://zapier.com/app/editor/template/1145389',
    link: 'https://procys.com/help-center-article/automatic-connection-between-holded-and-procys-were-integrated-now',
    available: true,
    img: images.holded,
    isAllowed: true
  },
  {
    id: 9,
    name: integrationNames.airtable,
    title: 'INTEGRATIONS_AIRTABLE_TITLE',
    description: 'INTEGRATIONS_AIRTABLE_DESCRIPTION',
    link: '',
    flowId: 'Cnx-jSysI',
    byZapier: false,
    available: true,
    img: images.airtable,
    appName: 'airtable',
    isAllowed: false
  },
  {
    id: 10,
    name: integrationNames.sftpReceiver,
    title: 'INTEGRATIONS_SFTP_RECEIVER_TITLE',
    description: 'INTEGRATIONS_SFTP_RECEIVER_DESCRIPTION',
    link: '',
    available: true,
    img: images.sftp,
    appName: 'sftp-receiver',
    isTarget: true,
    isAllowed: true
  },
  {
    id: 11,
    name: integrationNames.sftpSender,
    title: 'INTEGRATIONS_SFTP_SENDER_TITLE',
    description: 'INTEGRATIONS_SFTP_SENDER_DESCRIPTION',
    link: '',
    available: true,
    img: images.sftp,
    appName: 'sftp-sender',
    isTarget: true,
    isAllowed: true
  },
  {
    id: 12,
    name: integrationNames.onedrive,
    title: 'INTEGRATIONS_OD_TITLE',
    description: 'INTEGRATIONS_OD_DESCRIPTION',
    zapierLink: 'https://zapier.com/apps/procys/integrations/onedrive',
    link: '',
    byZapier: true,
    available: true,
    img: images.onedrive.default,
    isAllowed: true
  },
  {
    id: 13,
    name: integrationNames.dropbox,
    title: 'INTEGRATIONS_DB_TITLE',
    description: 'INTEGRATIONS_DB_DESCRIPTION',
    zapierLink: 'https://zapier.com/apps/procys/integrations/dropbox',
    link: '',
    byZapier: true,
    available: true,
    img: images.dropbox.default,
    isAllowed: true
  },
  {
    id: 14,
    name: integrationNames.googleDrive,
    title: 'INTEGRATIONS_GD_TITLE',
    description: 'INTEGRATIONS_GD_DESCRIPTION',
    zapierLink: 'https://zapier.com/apps/procys/integrations/google-drive',
    link: '',
    byZapier: true,
    available: true,
    img: images.gdrive.default,
    isAllowed: true
  },
  {
    id: 15,
    name: integrationNames.odoo,
    title: 'INTEGRATIONS_ODOO_TITLE',
    description: 'INTEGRATIONS_ODOO_DESCRIPTION',
    link: '',
    byZapier: false,
    available: false,
    img: images.odoo.default,
    isAllowed: true
  },
  {
    id: 16,
    name: integrationNames.sap,
    title: 'INTEGRATIONS_SAP_TITLE',
    description: 'INTEGRATIONS_SAP_DESCRIPTION',
    link: '',
    byZapier: false,
    available: false,
    img: images.sap.default,
    isAllowed: true
  },
  {
    id: 17,
    name: integrationNames.gstock,
    title: 'INTEGRATIONS_GSTOCK_TITLE',
    description: 'INTEGRATIONS_GSTOCK_DESCRIPTION',
    link: '',
    byZapier: false,
    available: true,
    img: images.gstock.default,
    isAllowed: true
  },
];

export const procysIntegrations = [
  'mbc',
  'tf',
  'assistantapi',
  'onedrive',
  'dropbox',
  'gdrive',
  'holded',
  'quickbooks-online'
];

export const docunectaIntegrations = ['gstock'];

export const customImplementations = [
  'isJumboTours',
  'zertifikaNonMandatory',
  'autoFillLineDescription',
  'supplierFilterRemoved',
  'supplierLabelWithVAT',
  'autoSaveDocument',
  'receiverVatLimitedOptions',
  'custom2FieldInDocumentForm',
  'toValidateStatus',
  'autoNavigation',
  'dontIncludeLinesToExport',
  'cdcDocumentView',
  'isoDate',
  'skipSupplierReview'
];

export const jumboToursCompanies = ['3388582381', '2120165208'];

export const makroSuppliers = ['ESA28647451', 'A28647451'];

export const cocaColaSuppliers = ['ESB86561412', 'B86561412'];

export const docunectaInvoiceFields = [
  'clientCode',
  'receiverCompany',
  'receiverVatNumber',
  'receiverAddress',
  'supplier',
  'senderVatNumber',
  'senderAddress',
  'documentNumber',
  'documentDate',
  'currency',
  'totalWeight',
  'packagesNumber',
  'subtotal',
  'vatAmount',
  'amount'
];

export const docunectaInvoiceLineFields = [
  'article',
  'lineDescription',
  'line',
  'lineQuantity',
  'lineProductCount',
  'lineUnitPrice',
  'linePrice',
  'lineDiscountedPrice',
  'lineDiscountAmountOnTotalPrice',
  'lineDiscount',
  'lineVatrate',
  'lineTax',
  'lineAmount'
];

export const invoiceRegex = {
  paymentRefOGMVCS: /^\+{3}\d{3}\/\d{4}\/\d{5}\+{3}$/,
  senderWebsite: /^[-a-zA-Z0-9@:/%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/,
  senderEmail: /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/,
  senderKvk: /^\d+$/,
  currency: /^[A-Za-z]{2,3}$/
};

export const invoiceHelperText = {
  senderVatNumber: 'INVALID_VAT_NUMBER_HELPER_TEXT',
  paymentRefOGMVCS: 'INVALID_PAYMENT_REFERENCE_HELPER_TEXT',
  senderWebsite: 'INVALID_URL_HELPER_TEXT',
  email: 'INVALID_EMAIL_ADDRESS_HELPER_TEXT',
  senderKvk: 'INVOICE_EDIT_FORM_SENDER_KVK_ONLY_DIGITS'
};

export const invoiceTooltips = {
  senderVatNumber: 'INVOICE_EDIT_FORM_SENDER_VAT_NUM_TOOLTIP',
  referenceNumber: 'INVOICE_EDIT_FORM_REFERENCIA_TOOLTIP',
  amount: 'INVOICE_EDIT_FORM_AMOUNT_TOOLTIP',
  period: 'INVOICE_EDIT_PERIOD_TOOLTIP',
  vatAmount2: 'INVOICE_EDIT_FORM_VAT_AMOUNT_2_TOOLTIP',
  vatPercentage2: 'INVOICE_EDIT_FORM_VAT_PERCENTAGE_2_TOOLTIP',
  discountAmount: 'INVOICE_EDIT_FORM_HEADER_DISCOUNT_AMOUNT_TOOLTIP',
  paymentRefOGMVCS: 'INVOICE_EDIT_FORM_PAY_REF_OGM_TOOLTIP',
  poReferenceNumber: 'INVOICE_EDIT_FORM_PO_REF_NUMBER_TOOLTIP',
  receiverAddress: 'INVOICE_EDIT_FORM_RECEIVER_ADDRESS_TOOTLIP',
  receiverCity: 'INVOICE_EDIT_FORM_RECEIVER_CITY_TOOLTIP',
  receiverStreet: 'INVOICE_EDIT_FORM_RECEIVER_STREET_TOOLTIP',
  receiverPostalCode: 'INVOICE_EDIT_FORM_RECEIVER_POSTAL_CODE_TOOLTIP',
  senderAddress: 'INVOICE_EDIT_FORM_SENDER_ADDRESS_TOOLTIP',
  senderCity: 'INVOICE_EDIT_FORM_SENDER_CITY_TOOLTIP',
  senderStreet: 'INVOICE_EDIT_FORM_SENDER_STREET_TOOLTIP',
  senderPostalCode: 'INVOICE_EDIT_FORM_SENDER_POSTAL_CODE_TOOLTIP',
  senderWebsite: 'INVOICE_EDIT_FORM_SENDER_WEBSITE',
  senderEmail: 'INVOICE_EDIT_FORM_SENDER_EMAIL_TOOLTIP',
  senderKvk: 'INVOICE_EDIT_FORM_SENDER_KVK_TOOLTIP',
  senderIban: 'INVOICE_EDIT_FORM_SENDER_IBAN_TOOLTIP',
  senderPhone: 'INVOICE_EDIT_FORM_SENDER_PHONE_TOOLTIP',
  senderFax: 'INVOICE_EDIT_FORM_SENDER_FAX_TOOLTIP',
  ocrNumberSE: 'INVOICE_EDIT_FORM_OCRNUMBERSE_TOOLTIP',
  documentType: 'INVOICE_EDIT_FORM_DOCUMENT_TYPE_TOOLTIP',
  secondaryDocumentType: 'INVOICE_EDIT_FORM_SECONDARY_DOCUMENT_TYPE_TOOLTIP',
};

export const invoiceLineTooltips = {
  lineAmount: 'INVOICE_EDIT_FORM_LINE_AMOUNT_TOOLTIP',
  lineUnitPrice: 'INVOICE_EDIT_FORM_LINE_UNIT_PRICE_TOOLTIP',
  lineQuantity: 'INVOICE_EDIT_FORM_LINE_QUANTITY_TOOLTIP',
  lineDiscount: 'INVOICE_EDIT_FORM_LINE_DISCOUNT_TOOLTIP',
  vatCode: 'INVOICE_EDIT_FORM_LINE_VATCODE_TOOLTIP',
  lineDiscountAmountOnUnitPrice: 'INVOICE_EDIT_FORM_LINE_DISCOUNT_AMOUNT_ON_UNIT_PRICE_TOOTLIP',
  lineDiscountAmountOnTotalPrice: 'INVOICE_EDIT_FORM_LINE_DISCOUNT_AMOUNT_ON_TOTAL_PRICE_TOOLTIP',
  lineVatrate: 'INVOICE_EDIT_FORM_LINE_VATRATE_TOOLTIP',
  lineTax: 'INVOICE_EDIT_FORM_LINE_TAX_TOOLTIP',
  linePrice: 'INVOICE_EDIT_FORM_LINE_PRICE_TOOLTIP',
  lineDiscountedPrice: 'INVOICE_EDIT_FORM_LINE_DISCOUNTED_PRICE_TOOLTIP',
  lineDescription: 'INVOICE_EDIT_FORM_LINE_DESCRIPTION_TOOLTIP'
};

export const twinfieldLineFields = [
  'line',
  'lineDescription',
  'lineGLAccount',
  'lineVatGroup',
  'lineCostCenter',
  'lineProject',
  'lineAmount',
  'lineExtraCost'
];

export const allFields = {
  invoice: [
    {
      key: 'documentType',
      assistantKey: 'document_type',
      label: 'INVOICE_EDIT_FORM_DOCUMENT_TYPE'
    },
    {
      key: 'clientCode',
      assistantKey: 'client_code',
      label: 'INVOICE_EDIT_FORM_CLIENT_CODE',
      isCustomField: true
    },
    {
      key: 'documentDate',
      assistantKey: 'date',
      label: 'INVOICE_EDIT_FORM_INVOICE_DATE',
      isMandatory: true
    },
    {
      key: 'invoicePeriod',
      assistantKey: 'invoice_period',
      label: 'INVOICE_EDIT_FORM_PERIOD',
      isMandatory: true
    },
    {
      key: 'documentNumber',
      assistantKey: 'number',
      label: 'INVOICE_EDIT_FORM_INVOICE_ID',
      isMandatory: true
    },
    {
      key: 'dueDate',
      assistantKey: 'due_date',
      label: 'INVOICE_EDIT_FORM_INVOICE_DUE_DATE'
    },
    {
      key: 'supplier',
      assistantKey: 'supplier',
      label: 'INVOICE_EDIT_FORM_SUPPLIER',
      isMandatory: true
    },
    {
      key: 'doNotPay',
      assistantKey: 'do_not_pay',
      label: 'INVOICE_EDIT_FORM_DO_NOT_PAY'
    },
    {
      key: 'senderCompany',
      assistantKey: 'sender_company',
      label: 'INVOICE_EDIT_FORM_SENDER_COMPANY'
    },
    {
      key: 'senderVatNumber',
      assistantKey: 'sender_VAT_number',
      label: 'INVOICE_EDIT_FORM_SENDER_VAT_NUM'
    },
    {
      key: 'currency',
      assistantKey: 'currency',
      label: 'INVOICE_EDIT_FORM_CURRENCY',
      isMandatory: true
    },
    {
      key: 'referenceNumber',
      assistantKey: 'reference_number',
      label: 'INVOICE_EDIT_FORM_REFERENCIA',
      isCustomField: true
    },
    {
      key: 'amount',
      assistantKey: 'amount',
      label: 'INVOICE_EDIT_FORM_AMOUNT',
      isMandatory: true
    },
    {
      key: 'vatAmount',
      assistantKey: 'vat_amount',
      label: 'INVOICE_EDIT_FORM_VAT_AMOUNT',
      isMandatory: true
    },
    {
      key: 'vatPercentage',
      assistantKey: 'vat_percentage',
      label: 'INVOICE_EDIT_FORM_VAT_PERCENTAGE'
    },
    {
      key: 'vatAmount2',
      assistantKey: 'vat_amount_2',
      label: 'INVOICE_EDIT_FORM_VAT_AMOUNT_2',
      isCustomField: true
    },
    {
      key: 'vatPercentage2',
      assistantKey: 'vat_percentage_2',
      label: 'INVOICE_EDIT_FORM_VAT_PERCENTAGE_2',
      isCustomField: true
    },
    {
      key: 'discountAmount',
      assistantKey: 'discount_amount',
      label: 'INVOICE_EDIT_FORM_HEADER_DISCOUNT_AMOUNT'
    },
    {
      key: 'description',
      assistantKey: 'description',
      label: 'INVOICE_EDIT_FORM_DESCRIPTION',
      isMandatory: true
    },
    {
      key: 'paymentRefOGMVCS',
      assistantKey: 'payment_ref_OGM_VCS',
      label: 'INVOICE_EDIT_FORM_PAY_REF_OGM'
    },
    {
      key: 'poReferenceNumber',
      assistantKey: 'PO_reference_number',
      label: 'INVOICE_EDIT_FORM_PO_REF_NUMBER'
    },
    {
      key: 'receiverCompany',
      assistantKey: 'receiver_company',
      label: 'INVOICE_EDIT_FORM_RECEIVER_COM'
    },
    {
      key: 'receiverVatNumber',
      assistantKey: 'receiver_VAT_number',
      label: 'INVOICE_EDIT_FORM_RECEIVER_VAT'
    },
    {
      key: 'receiverAddress',
      assistantKey: 'receiver_address',
      label: 'INVOICE_EDIT_FORM_RECEIVER_ADDRESS'
    },
    {
      key: 'receiverCity',
      assistantKey: 'receiver_city',
      label: 'INVOICE_EDIT_FORM_RECEIVER_CITY'
    },
    {
      key: 'receiverStreet',
      assistantKey: 'receiver_street',
      label: 'INVOICE_EDIT_FORM_RECEIVER_STREET'
    },
    {
      key: 'receiverPostalCode',
      assistantKey: 'receiver_postal_code',
      label: 'INVOICE_EDIT_FORM_RECEIVER_POSTAL_CODE'
    },
    {
      key: 'senderAddress',
      assistantKey: 'sender_address',
      label: 'INVOICE_EDIT_FORM_SENDER_ADDRESS'
    },
    {
      key: 'senderCity',
      assistantKey: 'sender_city',
      label: 'INVOICE_EDIT_FORM_SENDER_CITY'
    },
    {
      key: 'senderStreet',
      assistantKey: 'sender_street',
      label: 'INVOICE_EDIT_FORM_SENDER_STREET'
    },
    {
      key: 'senderPostalCode',
      assistantKey: 'sender_postal_code',
      label: 'INVOICE_EDIT_FORM_SENDER_POSTAL_CODE'
    },
    {
      key: 'senderWebsite',
      assistantKey: 'sender_website',
      label: 'INVOICE_EDIT_FORM_SENDER_WEBSITE'
    },
    {
      key: 'senderEmail',
      assistantKey: 'sender_email',
      label: 'INVOICE_EDIT_FORM_SENDER_EMAIL'
    },
    {
      key: 'senderKvk',
      assistantKey: 'sender_kvk',
      label: 'INVOICE_EDIT_FORM_SENDER_KVK'
    },
    {
      key: 'senderIban',
      assistantKey: 'sender_iban',
      label: 'INVOICE_EDIT_FORM_SENDER_IBAN'
    },
    {
      key: 'senderPhone',
      assistantKey: 'sender_phone',
      label: 'INVOICE_EDIT_FORM_SENDER_PHONE'
    },
    {
      key: 'senderFax',
      assistantKey: 'sender_fax',
      label: 'INVOICE_EDIT_FORM_SENDER_FAX'
    },
    {
      key: 'ocrNumberSE',
      assistantKey: 'ocr_number_se',
      label: 'INVOICE_EDIT_FORM_OCRNUMBERSE',
      isCustomField: true
    },
    {
      key: 'totalWeight',
      assistantKey: 'total_weight',
      label: 'INVOICE_EDIT_FORM_TOTAL_WEIGHT',
      isCustomField: true
    },
    {
      key: 'packagesNumber',
      assistantKey: 'packages_number',
      label: 'INVOICE_EDIT_FORM_PACKAGES_NUMBER',
      isCustomField: true
    },
    {
      key: 'subtotal',
      assistantKey: 'subtotal',
      label: 'INVOICE_EDIT_FORM_SUBTOTAL',
      isCustomField: true
    },
    {
      key: 'bookingNumber',
      assistantKey: 'booking_number',
      label: 'INVOICE_EDIT_FORM_BOOKING_NUMBER',
      isCustomField: true
    },
    {
      key: 'terms',
      assistantKey: 'terms',
      label: 'INVOICE_EDIT_FORM_TERMS',
      isCustomField: true
    }
  ],
  'invoice-line': [
    {
      key: 'line',
      label: 'INVOICE_EDIT_FORM_LINE_LINE',
      isMandatory: true
    },
    {
      key: 'lineDescription',
      label: 'INVOICE_EDIT_FORM_LINE_DESCRIPTION',
      isMandatory: true
    },
    {
      key: 'lineGLAccount',
      label: 'INVOICE_EDIT_FORM_LINE_GLA',
      isMandatory: true
    },
    {
      key: 'lineVatGroup',
      label: 'INVOICE_EDIT_FORM_LINE_VAT_GROUP',
      isMandatory: true
    },
    {
      key: 'lineCostCenter',
      label: 'INVOICE_EDIT_FORM_LINE_CC',
      isMandatory: true
    },
    {
      key: 'lineProject',
      label: 'INVOICE_EDIT_FORM_LINE_PROJECTS'
    },
    {
      key: 'lineUnitPrice',
      label: 'INVOICE_EDIT_FORM_LINE_UNIT_PRICE',
      isMandatory: true
    },
    {
      key: 'lineQuantity',
      label: 'INVOICE_EDIT_FORM_LINE_QUANTITY',
      isMandatory: true
    },
    {
      key: 'lineDiscount',
      label: 'INVOICE_EDIT_FORM_LINE_DISCOUNT',
      isMandatory: true
    },
    {
      key: 'lineVatrate',
      label: 'INVOICE_EDIT_FORM_LINE_VATRATE',
      isMandatory: true
    },
    {
      key: 'lineDiscountedPrice',
      label: 'INVOICE_EDIT_FORM_LINE_DISCOUNTED_PRICE',
      isMandatory: true
    },
    {
      key: 'lineAmount',
      label: 'INVOICE_EDIT_FORM_LINE_AMOUNT',
      isMandatory: true
    },
    {
      key: 'linePrice',
      label: 'INVOICE_EDIT_FORM_LINE_PRICE'
    },
    {
      key: 'article',
      label: 'INVOICE_EDIT_FORM_LINE_ARTICLE'
    },
    {
      key: 'vatCode',
      label: 'INVOICE_EDIT_FORM_LINE_VAT_GROUP'
    },
    {
      key: 'lineDiscountAmountOnUnitPrice',
      label: 'INVOICE_EDIT_FORM_LINE_DISCOUNT_AMOUNT_ON_UNIT_PRICE'
    },
    {
      key: 'lineDiscountAmountOnTotalPrice',
      label: 'INVOICE_EDIT_FORM_LINE_DISCOUNT_AMOUNT_ON_TOTAL_PRICE'
    },
    {
      key: 'lineTax',
      label: 'INVOICE_EDIT_FORM_LINE_TAX'
    },
    {
      key: 'lineDeposit',
      label: 'INVOICE_EDIT_FORM_LINE_DEPOSIT'
    },
    {
      key: 'lineExtraCost',
      label: 'INVOICE_EDIT_FORM_LINE_EXTRA_COST'
    },
    {
      key: 'lineReferenceNumber',
      label: 'INVOICE_EDIT_FORM_LINE_REF_NO'
    },
    {
      key: 'lineProductCount',
      label: 'INVOICE_EDIT_FORM_LINE_PRODUCT_COUNT',
      isCustomField: true
    },
    {
      key: 'lineCategory',
      label: 'INVOICE_EDIT_FORM_LINE_CATEGORY'
    },
    {
      key: 'lineProductCode',
      label: 'INVOICE_EDIT_FORM_LINE_PRODUCT_CODE'
    },
    {
      key: 'lineTotalPV',
      label: 'INVOICE_EDIT_FORM_LINE_TOTAL_PV'
    }
  ],
  supplier: [
    {
      key: 'creditorCode',
      label: 'SUPPLIERS_CREDITOR_CODE',
      isUpdateField: true
    },
    {
      key: 'creditorName',
      label: 'SUPPLIERS_CREDITOR_NAME',
      isMandatory: true,
      isUpdateField: true
    },
    {
      key: 'fiscalName',
      label: 'SUPPLIERS_FISCAL_NAME'
    },
    {
      key: 'autoPaymentStatus',
      label: 'SUPPLIERS_AUTO_PAYMENT_STATUS',
      isMandatory: true
    },
    {
      key: 'dueDays',
      label: 'SUPPLIERS_DUE_DAYS'
    },
    {
      key: 'website',
      label: 'SUPPLIERS_WEBSITE'
    },
    {
      key: 'comment',
      label: 'SUPPLIERS_COMMENT'
    },
    {
      key: 'companyRegNumber',
      label: 'SUPPLIERS_COMPANY_REGISTRATION_NUMBER',
      isUpdateField: true
    },
    {
      key: 'vatNumber',
      label: 'SUPPLIERS_VAT_NUMBER',
      isUpdateField: true
    },
    {
      key: 'referenceCode',
      label: 'SUPPLIERS_REFERENCE_CODE',
      isUpdateField: true
    },
    {
      key: 'status',
      label: 'SUPPLIERS_STATUS'
    },
    {
      key: 'generalLedgerAccount',
      label: 'SUPPLIERS_GENERAL_LEDGER_ACCOUNT',
      isMandatory: true
    },
    {
      key: 'vatGroup',
      label: 'SUPPLIERS_VAT_GROUP',
      isMandatory: true
    },
    {
      key: 'generalLedgerAccLine',
      label: 'SUPPLIERS_GENERAL_LEDGER_ACCOUNT_LINE'
    },
    {
      key: 'vatGroupLine',
      label: 'SUPPLIERS_VAT_GROUP_LINE'
    },
    {
      key: 'invoiceDescriptionRule',
      label: 'SUPPLIERS_INVOICE_DESCRIPTION_RULE'
    },
    {
      key: 'invoiceLineDescriptionRule',
      label: 'SUPPLIERS_INVOICE_LINE_DESCRIPTION_RULE'
    },
    {
      key: 'defaultCurrency',
      label: 'SUPPLIERS_DEFAULT_CURRENCY'
    },
    {
      key: 'bankCountry',
      label: 'SUPPLIERS_BANK_COUNTRY'
    },
    {
      key: 'ascription',
      label: 'SUPPLIERS_ASCRIPTION'
    },
    {
      key: 'iban',
      label: 'SUPPLIERS_IBAN'
    },
    {
      key: 'bankAccNumber',
      label: 'SUPPLIERS_BANK_ACCOUNT_NUMBER'
    },
    {
      key: 'bicSwift',
      label: 'SUPPLIERS_BIC_SWIFT'
    },
    {
      key: 'nationalBankCode',
      label: 'SUPPLIERS_NATIONAL_BANK_CODE'
    },
    {
      key: 'bankName',
      label: 'SUPPLIERS_BANK_NAME'
    },
    {
      key: 'bankCity',
      label: 'SUPPLIERS_BANK_CITY'
    },
    {
      key: 'bankStreet',
      label: 'SUPPLIERS_BANK_STREET'
    },
    {
      key: 'bankHouseNumber',
      label: 'SUPPLIERS_BANK_HOUSE_NUMBER'
    },
    {
      key: 'country',
      label: 'SUPPLIERS_COUNTRY',
      isMandatory: true
    },
    {
      key: 'city',
      label: 'SUPPLIERS_CITY'
    },
    {
      key: 'postalCode',
      label: 'SUPPLIERS_BANK_POSTCODE'
    },
    {
      key: 'address1',
      label: 'SUPPLIERS_ADDRESS_ONE'
    },
    {
      key: 'address2',
      label: 'SUPPLIERS_ADDRESS_TWO'
    },
    {
      key: 'poBox',
      label: 'SUPPLIERS_PO_BOX'
    },
    {
      key: 'poBoxPostalCode',
      label: 'SUPPLIERS_PO_BOX_POSTAL_CODE'
    },
    {
      key: 'poBoxCity',
      label: 'SUPPLIERS_PO_BOX_CITY'
    },
    {
      key: 'contactPersonName',
      label: 'SUPPLIERS_CONTACT_PERSON_NAME'
    },
    {
      key: 'telephoneNumber',
      label: 'SUPPLIERS_TELEPHONE_NUMBER',
      isUpdateField: true
    },
    {
      key: 'faxNumber',
      label: 'SUPPLIERS_FAX_NUMBER'
    },
    {
      key: 'mobileTelNumber',
      label: 'SUPPLIERS_MOBILE_TELEPHONE_NUMBER'
    },
    {
      key: 'emailAddress',
      label: 'SUPPLIERS_EMAIL_ADDRESS',
      isUpdateField: true
    },
    {
      key: 'postcode',
      label: 'SUPPLIERS_BANK_POSTCODE'
    },
    {
      key: 'state',
      label: 'SUPPLIERS_BANK_STATE'
    },
    // {
    //   key: 'addressName',
    //   label: 'Address name'
    // },
    // {
    //   key: 'shortname',
    //   label: 'Short name'
    // },
    {
      key: 'discountDays',
      label: 'SUPPLIERS_DISCOUNT_DAYS'
    },
    {
      key: 'discountPercentage',
      label: 'SUPPLIERS_DISCOUNT_PERCENTAGE'
    },
    {
      key: 'matchType',
      label: 'SUPPLIERS_MATCH_TYPE'
    },
    {
      key: 'custom1',
      label: 'SUPPLIERS_CUSTOM_FIELD_01'
    },
    {
      key: 'custom2',
      label: 'SUPPLIERS_CUSTOM_FIELD_02'
    },
    {
      key: 'custom3',
      label: 'SUPPLIERS_CUSTOM_FIELD_03'
    }
  ],
  gla: [
    {
      key: 'name',
      label: 'GLA_NAME',
      isMandatory: true,
      isUpdateField: true
    },
    {
      key: 'vat',
      label: 'GLA_VAT',
      isMandatory: true
    },
    {
      key: 'code',
      label: 'GLA_CODE',
      isUpdateField: true
    },
    {
      key: 'fromYear',
      label: 'GLA_FROM_YEAR'
    },
    {
      key: 'fromPeriod',
      label: 'GLA_FROM_PERIOD'
    },
    {
      key: 'endYear',
      label: 'GLA_END_YEAR'
    },
    {
      key: 'endPeriod',
      label: 'GLA_END_PERIOD'
    },
    {
      key: 'accountNumber',
      label: 'GLA_ACCOUNT_NUMBER',
      isUpdateField: true
    },
    {
      key: 'accountHolder',
      label: 'GLA_ACCOUNT_HOLDER',
      isUpdateField: true
    },
    {
      key: 'furtherAnalysis',
      label: 'GLA_FURTHER_ANALYSIS'
    },
    {
      key: 'matchable',
      label: 'GLA_MATCHABLE'
    },
    {
      key: 'vatType',
      label: 'GLA_VAT_TYPE'
    },
    {
      key: 'performanceType',
      label: 'GLA_PERFORMANCE_TYPE'
    }
  ],
  vat: [
    {
      key: 'name',
      label: 'VAT_CODE_NAME',
      isMandatory: true,
      isUpdateField: true
    },
    {
      key: 'ratePercentage',
      label: 'VAT_CODE_RATE_PERCENTAGE',
      isMandatory: true
    },
    {
      key: 'code',
      label: 'VAT_CODE_CODE',
      isUpdateField: true
    },
    {
      key: 'type',
      label: 'VAT_CODE_TYPE'
    },
    {
      key: 'shortName',
      label: 'VAT_CODE_SHORT_NAME',
      isUpdateField: true
    },
    {
      key: 'invoiceText',
      label: 'VAT_CODE_INVOICE_TEXT'
    },
    {
      key: 'rateName',
      label: 'VAT_CODE_RATE_NAME'
    },
    {
      key: 'rateShortName',
      label: 'VAT_CODE_RATE_SHORT_NAME'
    },
    {
      key: 'ruleAccount',
      label: 'VAT_CODE_RULE_ACCOUNT'
    },
    {
      key: 'rulePercentage',
      label: 'VAT_CODE_RULE_PERCENTAGE_RULE'
    },
    {
      key: 'ruleCostCenter',
      label: 'VAT_CODE_RULE_COST_CENTER'
    },
    {
      key: 'ruleVatGroup',
      label: 'VAT_CODE_RULE_VAT_GROUP'
    }
  ],
  cc: [
    {
      key: 'name',
      label: 'COST_CENTER_NAME',
      isMandatory: true,
      isUpdateField: true
    },
    {
      key: 'code',
      label: 'COST_CENTER_CODE',
      isUpdateField: true
    }
  ],
  'invoice-line-display': [
    {
      key: 'lineDescription',
      label: 'INVOICE_EDIT_FORM_LINE_DESCRIPTION',
      isAvailable: true,
      isActive: true,
      isRequired: true,
      isMandatory: true
    },
    {
      key: 'lineGLAccount',
      label: 'INVOICE_EDIT_FORM_LINE_GLA',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineVatGroup',
      label: 'INVOICE_EDIT_FORM_LINE_VAT_GROUP',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineCostCenter',
      label: 'INVOICE_EDIT_FORM_LINE_CC',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineProject',
      label: 'INVOICE_EDIT_FORM_LINE_PROJECTS',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineUnitPrice',
      label: 'INVOICE_EDIT_FORM_LINE_UNIT_PRICE',
      isAvailable: true,
      isActive: true,
      isRequired: false,
      isMandatory: true
    },
    {
      key: 'lineQuantity',
      label: 'INVOICE_EDIT_FORM_LINE_QUANTITY',
      isAvailable: true,
      isActive: true,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineDiscount',
      label: 'INVOICE_EDIT_FORM_LINE_DISCOUNT',
      isAvailable: true,
      isActive: true,
      isRequired: false,
      isMandatory: true
    },
    {
      key: 'lineAmount',
      label: 'INVOICE_EDIT_FORM_LINE_AMOUNT',
      isAvailable: true,
      isActive: true,
      isRequired: true,
      isMandatory: true
    },
    {
      key: 'lineTax',
      label: 'INVOICE_EDIT_FORM_LINE_TAX',
      isAvailable: true,
      isActive: true,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineReferenceNumber',
      label: 'INVOICE_EDIT_FORM_LINE_REF_NO',
      isAvailable: false,
      isActive: true,
      isRequired: false,
      isMandatory: false,
      isCustomField: true
    },
    {
      key: 'lineProductCount',
      label: 'INVOICE_EDIT_FORM_LINE_PRODUCT_COUNT',
      isAvailable: false,
      isActive: true,
      isRequired: false,
      isMandatory: false,
      isCustomField: true
    },
    {
      key: 'linePrice',
      label: 'INVOICE_EDIT_FORM_LINE_PRICE',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineArticle',
      label: 'INVOICE_EDIT_FORM_LINE_ARTICLE',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineArticleClient',
      label: 'INVOICE_EDIT_FORM_LINE_ARTICLE_CLIENT',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineDiscountAmountOnUnitPrice',
      label: 'INVOICE_EDIT_FORM_LINE_DISCOUNT_AMOUNT_ON_UNIT_PRICE',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineDiscountAmountOnTotalPrice',
      label: 'INVOICE_EDIT_FORM_LINE_DISCOUNT_AMOUNT_ON_TOTAL_PRICE',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineDiscountedPrice',
      label: 'INVOICE_EDIT_FORM_LINE_DISCOUNTED_PRICE',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineVatrate',
      label: 'INVOICE_EDIT_FORM_LINE_VATRATE',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineTax',
      label: 'INVOICE_EDIT_FORM_LINE_TAX',
      isAvailable: true,
      isActive: true,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineVatcode',
      label: 'INVOICE_EDIT_FORM_LINE_VAT_GROUP',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineDeposit',
      label: 'INVOICE_EDIT_FORM_LINE_DEPOSIT',
      isAvailable: true,
      isActive: false,
      isRequired: false,
      isMandatory: false
    },
    {
      key: 'lineReferenceNumber',
      label: 'INVOICE_EDIT_FORM_LINE_REF_NO',
      isAvailable: false,
      isActive: false,
      isRequired: false,
      isMandatory: false,
      isCustomField: true
    },
    {
      key: 'lineUnitMeasurement',
      label: 'INVOICE_EDIT_FORM_LINE_UNIT_MEASUREMENT',
      isAvailable: false,
      isActive: false,
      isRequired: false,
      isMandatory: false,
      isCustomField: true
    },
    {
      key: 'lineBaseAmount',
      label: 'INVOICE_EDIT_FORM_LINE_BASE_AMOUNT',
      isAvailable: false,
      isActive: false,
      isRequired: false,
      isMandatory: false,
      isCustomField: true
    },
    {
      key: 'lineRetentionAmount',
      label: 'INVOICE_EDIT_FORM_LINE_RETENTION_AMOUNT',
      isAvailable: false,
      isActive: false,
      isRequired: false,
      isMandatory: false,
      isCustomField: true
    },
    {
      key: 'lineRetentionPercentage',
      label: 'INVOICE_EDIT_FORM_LINE_RETENTION_PERCENTAGE',
      isAvailable: false,
      isActive: false,
      isRequired: false,
      isMandatory: false,
      isCustomField: true
    },
    {
      key: 'lineCategory',
      label: 'INVOICE_EDIT_FORM_LINE_CATEGORY',
      isAvailable: false,
      isActive: false,
      isRequired: false,
      isMandatory: false,
      isCustomField: true
    },
    {
      key: 'lineProductCode',
      label: 'INVOICE_EDIT_FORM_LINE_PRODUCT_CODE',
      isAvailable: false,
      isActive: false,
      isRequired: false,
      isMandatory: false,
      isCustomField: true
    },
    {
      key: 'lineTotalPV',
      label: 'INVOICE_EDIT_FORM_LINE_TOTAL_PV',
      isAvailable: false,
      isActive: false,
      isRequired: false,
      isMandatory: false,
      isCustomField: true
    },
    {
      key: 'lineMeasureType',
      label: 'INVOICE_EDIT_FORM_LINE_MEASURE_TYPE',
      isAvailable: false,
      isActive: false,
      isRequired: false,
      isMandatory: false,
      isCustomField: true
    }
  ]
};

export const ERRORS = {
  INCORRECT_OLD_PASWORD: 'Incorrect old pasword',
  CHANGE_PASSWORD_LOCKED: 'User is locked, can not change password',
  EMAIL_ALREADY_REGISTERED: 'Email is already registered',
  'Error while changing the password in ML-Assistant':
    'Error while changing the password in ML-Assistant'
};

export const glaMsg = {
  GLA_TITLE: 'DASHBOARD_GLA_LOGS',
  GLA_SEARCH_LOG: 'DASHBOARD_SEARCH_GLA_LOGS_SEARCH',
  GLA_BACK_TO_OVERVIEW: 'GLA_EDIT_BACK_TO_OVERVIEW',
  GLA_DASHBOARD_FILTERED: 'DASHBOARD_GLA_FILTERED',
  GLA_TABLE_TOOLTIP: 'GLA_LIST_VIEW_LOGS_DETAILS_TOOLTIP',
  GLA_LOG_VIEW_DIMENSION_CODE: 'GLA_LOG_VIEW_DIMENSION_CODE',
  GLA_BALANCE: 'GLA_BALANCE',
  GLA_PNL: 'GLA_PNL'
};

export const vatMsg = {
  VAT_CODE_TITLE: 'DASHBOARD_VAT_LOGS',
  VAT_CODE_SEARCH_LOG: 'DASHBOARD_SEARCH_VATCODE_LOGS_SEARCH',
  VAT_CODE_BACK_TO_OVERVIEW: 'VAT_EDIT_BACK_TO_OVERVIEW',
  VAT_CODE_DASHBOARD_FILTERED: 'DASHBOARD_VAT_FILTERED',
  VAT_CODE_TABLE_TOOLTIP: 'VAT_LIST_VIEW_LOGS_DETAILS_TOOLTIP',
  VAT_CODE_LOG_VIEW_DIMENSION_CODE: 'VAT_CODE_LOG_VIEW_DIMENSION_CODE'
};

export const cctMsg = {
  COST_CENTER_TITLE: 'DASHBOARD_COST_CENTER_LOGS',
  COST_CENTER_SEARCH_LOG: 'DASHBOARD_SEARCH_COST_CENTER_LOGS_SEARCH',
  COST_CENTER_BACK_TO_OVERVIEW: 'COST_CENTER_EDIT_BACK_TO_OVERVIEW',
  COST_CENTER_DASHBOARD_FILTERED: 'DASHBOARD_COST_CENTER_FILTERED',
  COST_CENTER_TABLE_TOOLTIP: 'COST_CENTER_LIST_VIEW_LOGS_DETAILS_TOOLTIP',
  COST_CENTER_LOG_VIEW_DIMENSION_CODE: 'COST_CENTER_LOG_VIEW_DIMENSION_CODE'
};

export const prjtMsg = {
  PROJECT_TITLE: 'DASHBOARD_PROJECT_LOGS',
  PROJECT_SEARCH_LOG: 'DASHBOARD_SEARCH_PROJECT_LOGS_SEARCH',
  PROJECT_BACK_TO_OVERVIEW: 'PROJECT_EDIT_BACK_TO_OVERVIEW',
  PROJECT_DASHBOARD_FILTERED: 'DASHBOARD_PROJECT_FILTERED',
  PROJECT_TABLE_TOOLTIP: 'PROJECT_LIST_VIEW_LOGS_DETAILS_TOOLTIP',
  PROJECT_LOG_VIEW_DIMENSION_CODE: 'PROJECT_LOG_VIEW_DIMENSION_CODE'
};

export const defaultMsg = {
  DEFAULT_SEARCH_LOG: 'DASHBOARD_SEARCH_DIMENSION_LOGS_SEARCH',
  DEFAULT_BACK_TO_OVERVIEW: 'DIMENSION_EDIT_BACK_TO_OVERVIEW',
  DEFAULT_TITLE: 'DASHBOARD_DIMENSION_LOGS',
  DEFAULT_DASHBOARD_FILTERED: 'DASHBOARD_DIMENSION_FILTERED',
  DEFAULT_TABLE_TOOLTIP: 'DIMENSION_LIST_VIEW_LOGS_DETAILS_TOOLTIP',
  DEFAULT_LOG_VIEW_DIMENSION_CODE: 'DEFAULT_LOG_VIEW_DIMENSION_CODE'
};

export const invoicePageRefreshTimer = 10000;

export const statsPageRefreshTimer = 10000;

export const lineAmountRelatedFields = [
  'lineAmount',
  'lineQuantity',
  'lineUnitPrice',
  'linePrice',
  'lineDiscount',
  'lineDiscountAmountOnUnitPrice',
  'lineDiscountAmountOnTotalPrice',
  'lineDiscountedPrice',
  'lineVatrate',
  'lineTax',
  'lineDiscountedPrice',
  'lineProductCount',
  'lineBaseAmount',
  'lineRetentionAmount',
  'lineRetentionPercentage',
  'lineTotalPV',
  'lineMeasureType',
];

export const amountRelatedFields = [
  'amount',
  'vatAmount',
  'vatAmount2',
  'vatPercentage',
  'vatPercentage2',
  'discountAmount',
  'subtotal',
  'totalWeight',
  'baseAmount',
  'discountRate',
  'equivalenceSurchargeAmount',
  'equivalenceSurchargeRate',
  'retentionAmount',
  'retentionPercentage',
  'gstAmount',
  'pstAmount',
  'totalAmountBeforeDiscount',
  'mnjBaseAmount',
  ...lineAmountRelatedFields
];

export const dateRelatedFields = [
  'documentDate',
  'dueDate',
  'deliveryDate',
  'dateOfBirth',
  'dateOfIssue',
  'dateOfExpiry',
  'dateOfIssue2',
  'mnjPeriodStart',
  'mnjPeriodEnd',
];

export const lineDateRelatedFields = ['lineDeliveryDate'];

export const nonNumericFieldsHeader = [
  'period',
  'documentNumber',
  'supplier',
  'description',
  'poReferenceNumber',
  'receiverCompany',
  'receiverVatNumber',
  'receiverAddress',
  'receiverCity',
  'receiverStreet',
  'receiverPostalCode',
  'receiverTaxNumber',
  'senderCompany',
  'senderVatNumber',
  'senderAddress',
  'senderCity',
  'senderStreet',
  'senderPostalCode',
  'senderWebsite',
  'senderEmail',
  'senderKvk',
  'senderIban',
  'senderPhone',
  'senderFax',
  'senderTaxNumber',
  'clientCode',
  'bookingNumber',
  'archiveNumber',
  'contactNumber',
  'estimationNumber',
  'placeAndDateOfEstimation',
  'estimationPeriod',
  'ccoste',
  'deliveryNoteNumber',
  'referenceNumber2',
  'contractNumber',
  'mnjDescription',
  'senderContactName',
  'mnjPlace',
  'dossierNumber',
];

export const nonNumericFieldsLines = [
  'lineDescription',
  'lineGLAccount',
  'lineVatGroup',
  'lineCostCenter',
  'lineProject',
  'lineArticle',
  'lineVatcode',
  'lineDeposit',
  'lineCategory',
  'lineProductCode',
  'lineDelRecCompName',
  'lineDelRecContName',
  'lineDelRecAddress',
  'lineDelRecPhoneNumber',
  'lineDelRecEmail',
  'lineTeilnr',
  'lineTeilnrDelivery'
];

export const dimensionExportOptions = [
  {
    id: 1,
    key: 'csv',
    label: 'DIMENSION_EXPORT_CSV'
  }
  // {
  //   id: 2,
  //   key: 'xlsx',
  //   label: 'DIMENSION_EXPORT_XLSX'
  // }
];

export const dimensionsMap = {
  supplier: 'suppliers',
  gla: 'glaccounts',
  cc: 'costcenters',
  vat: 'vatcodes'
};

export const translateMap = {
  supplier: 'SUPPLIER',
  gla: 'GLA',
  cc: 'CC',
  vat: 'VAT'
};

export const translateMapLogs = {
  suppliers: 'SUPPLIER',
  glaccounts: 'GLA',
  costcenters: 'CC',
  vatcodes: 'VAT'
};

export const dimensionImportStatusCheckTimer = 2000;

export const externalLinks = {
  knoldgeBase: 'https://procys.com__LANG__/help-center',
};

export const kbLinks = {
  Procys: 'https://procys.com__LANG__/help-center',
  Extrable: 'https://extrable.es/#FormularioExtrable'
};

export const vatLineLabels = {
  baseAmount: 'VAT_LINE_BASE_AMOUNT',
  vatRate: 'VAT_LINE_VAT_RATE',
  vatAmount: 'VAT_LINE_VAT_AMOUNT'
};

export const esLineLabels = {
  baseAmount: 'VAT_LINE_BASE_AMOUNT',
  esRate: 'SURCHARGE_LINE_SURCHARGE_RATE',
  esAmount: 'SURCHARGE_LINE_SURCHARGE_AMOUNT'
};

export const vatLineLabelsForExport = {
  baseAmount: 'VAT_LINE_EXPORT_BASE_AMOUNT',
  vatRate: 'VAT_LINE_EXPORT_VAT_RATE',
  vatAmount: 'VAT_LINE_EXPORT_VAT_AMOUNT'
};

export const esLineLabelsForExport = {
  baseAmount: 'ES_LINE_EXPORT_BASE_AMOUNT',
  esRate: 'ES_LINE_EXPORT_ES_RATE',
  esAmount: 'ES_LINE_EXPORT_ES_AMOUNT'
};

export const maxUploadCount = 1000;

export const cdcDocumentFields = [
  {
    assistantKey: 'number',
    key: 'documentNumber',
    label: 'INVOICE_EDIT_FORM_INVOICE_ID',
    isCustomField: false,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    isRequired: true,
    section: 'header'
  },
  {
    assistantKey: 'date',
    key: 'documentDate',
    label: 'INVOICE_EDIT_FORM_INVOICE_DATE',
    isCustomField: false,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    isRequired: true,
    section: 'header'
  },
  {
    assistantKey: 'period',
    key: 'period',
    label: 'INVOICE_EDIT_FORM_PERIOD',
    isCustomField: false,
    isActive: true,
    isAvailable: true,
    isMandatory: false,
    section: 'header'
  },
  {
    assistantKey: 'sender_company',
    key: 'senderCompany',
    label: 'CDC_DOC_EDIT_FORM_SENDER_COMPANY',
    isCustomField: false,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'header'
  },
  {
    assistantKey: 'sender_address',
    key: 'senderAddress',
    label: 'INVOICE_EDIT_FORM_SENDER_ADDRESS',
    isCustomField: false,
    isActive: true,
    isAvailable: true,
    isMandatory: false,
    section: 'header'
  },
  {
    assistantKey: 'receiver_company',
    key: 'receiverCompany',
    label: 'CDC_DOC_EDIT_FORM_RECEIVER_COMPANY',
    isCustomField: false,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'header'
  },
  {
    assistantKey: 'milk_total_volume',
    key: 'milkTotalVolume',
    label: 'CDC_DOC_EDIT_FORM_TOTAL_VOLUME',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'header'
  },
  {
    assistantKey: 'milk_unit_price',
    key: 'milkUnitPrice',
    label: 'CDC_DOC_EDIT_FORM_UNIT_PRICE',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'header'
  },
  {
    assistantKey: 'milk_total_price',
    key: 'milkTotalPrice',
    label: 'CDC_DOC_EDIT_FORM_TOTAL_PRICE',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'header'
  },
  {
    assistantKey: 'milk_total_amount_novat',
    key: 'milkTotalAmountNovat',
    label: 'CDC_DOC_EDIT_FORM_TOTAL_AMOUNT_NO_VAT',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'header'
  },
  {
    assistantKey: 'milk_fat',
    key: 'milkFat',
    label: 'CDC_DOC_EDIT_FORM_FAT',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'fat'
  },
  {
    assistantKey: 'milk_fat_ref',
    key: 'milkFatRef',
    label: 'CDC_DOC_EDIT_FORM_FAT_REF',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'fat'
  },
  {
    assistantKey: 'milk_fat_adj',
    key: 'milkFatAdj',
    label: 'CDC_DOC_EDIT_FORM_FAT_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'fat'
  },
  {
    assistantKey: 'milk_prot',
    key: 'milkProt',
    label: 'CDC_DOC_EDIT_FORM_PROT',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'protein'
  },
  {
    assistantKey: 'milk_prot_ref',
    key: 'milkProtRef',
    label: 'CDC_DOC_EDIT_FORM_PROT_REF',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'protein'
  },
  {
    assistantKey: 'milk_prot_adj',
    key: 'milkProtAdj',
    label: 'CDC_DOC_EDIT_FORM_PROT_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'protein'
  },
  {
    assistantKey: 'milk_cell',
    key: 'milkCell',
    label: 'CDC_DOC_EDIT_FORM_CELL',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'cell'
  },
  {
    assistantKey: 'milk_cell_ref',
    key: 'milkCellRef',
    label: 'CDC_DOC_EDIT_FORM_CELL_REF',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'cell'
  },
  {
    assistantKey: 'milk_cell_adj',
    key: 'milkCellAdj',
    label: 'CDC_DOC_EDIT_FORM_CELL_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'cell'
  },
  {
    assistantKey: 'milk_bact',
    key: 'milkBact',
    label: 'CDC_DOC_EDIT_FORM_BACT',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'bact'
  },
  {
    assistantKey: 'milk_bact_ref',
    key: 'milkBactRef',
    label: 'CDC_DOC_EDIT_FORM_BACT_REF',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'bact'
  },
  {
    assistantKey: 'milk_bact_adj',
    key: 'milkBactAdj',
    label: 'CDC_DOC_EDIT_FORM_BACT_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'bact'
  },
  {
    assistantKey: 'milk_qual_adj',
    key: 'milkQualAdj',
    label: 'CDC_DOC_EDIT_FORM_QUAL_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_aa_adj',
    key: 'milkAaAdj',
    label: 'CDC_DOC_EDIT_FORM_AA_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_pcl_adj',
    key: 'milkPclAdj',
    label: 'CDC_DOC_EDIT_FORM_PCL_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_cuota_adj',
    key: 'milkCuotaAdj',
    label: 'CDC_DOC_EDIT_FORM_QUOTA_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_homo_adj',
    key: 'milkHomoAdj',
    label: 'CDC_DOC_EDIT_FORM_HOMO_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_comp_adj',
    key: 'milkCompAdj',
    label: 'CDC_DOC_EDIT_FORM_COMP_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_frio_adj',
    key: 'milkFrioAdj',
    label: 'CDC_DOC_EDIT_FORM_FRIO_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_saa_adj',
    key: 'milkSaaAdj',
    label: 'CDC_DOC_EDIT_FORM_SAA_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_assoc_adj',
    key: 'milkAssocAdj',
    label: 'CDC_DOC_EDIT_FORM_ASSOC_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_inhib',
    key: 'milkInhib',
    label: 'CDC_DOC_EDIT_FORM_INHIB',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_inhib_adj',
    key: 'milkInhibAdj',
    label: 'CDC_DOC_EDIT_FORM_INHIB_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_loyal_adj',
    key: 'milkLoyalAdj',
    label: 'CDC_DOC_EDIT_FORM_LOYAL_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_hygi_adj',
    key: 'milkHygiAdj',
    label: 'CDC_DOC_EDIT_FORM_HYGI_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'adj'
  },
  {
    assistantKey: 'milk_lab_cost',
    key: 'milkLabCost',
    label: 'CDC_DOC_EDIT_FORM_LAB_COST',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'lab'
  },
  {
    assistantKey: 'milk_lab_adj',
    key: 'milkLabAdj',
    label: 'CDC_DOC_EDIT_FORM_LAB_ADJ',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'lab'
  },
  {
    assistantKey: 'milk_lab_cost_deducted',
    key: 'milkLabCostDeducted',
    label: 'CDC_DOC_EDIT_FORM_LAB_COST_DEDUCTED',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'lab'
  },
  {
    assistantKey: 'milk_cuota',
    key: 'milkCuota',
    label: 'CDC_DOC_EDIT_FORM_QUOTA',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'cuota'
  },
  {
    assistantKey: 'milk_cuota_delivered',
    key: 'milkCuotaDelivered',
    label: 'CDC_DOC_EDIT_FORM_QUOTA_DELIVERED',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'cuota'
  },
  {
    assistantKey: 'milk_cuota_remaining',
    key: 'milkCuotaRemaining',
    label: 'CDC_DOC_EDIT_FORM_QUOTA_REMAINING',
    isCustomField: true,
    isActive: true,
    isAvailable: true,
    isMandatory: true,
    section: 'cuota'
  }
];

export const paymentFormValues = {
  tarjeta: 'TARJETA',
  transferencia: 'TRANSFERENCIA',
  recibo: 'RECIBO',
  otro: 'OTRO'
};

export const typeOfTransactions = {
  buying: 'BUYING',
  priceIncrease: 'PRICE_INCREASE',
  posteriorReduction: 'POSTERIOR_REDUCTION',
  annulment: 'ANNULMENT'
};

export const documentEditSelectValues = {
  documentType: [
    documentTypes.invoice,
    documentTypes.purchaseOrder,
    documentTypes.creditNote,
    documentTypes.attachment,
    documentTypes.receipt,
    documentTypes.passportOrId
  ],
  secondaryDocumentType: [
    secondaryDocumentTypes.invoice,
    secondaryDocumentTypes.deliveryNote,
    secondaryDocumentTypes.creditNote,
    secondaryDocumentTypes.debitNote,
    secondaryDocumentTypes.order,
    secondaryDocumentTypes.cancellation
  ],
  typeOfTransaction: [
    typeOfTransactions.buying,
    typeOfTransactions.priceIncrease,
    typeOfTransactions.posteriorReduction,
    typeOfTransactions.annulment,
  ],
  paymentForm: [
    paymentFormValues.transferencia,
    paymentFormValues.recibo,
    paymentFormValues.otro,
    paymentFormValues.tarjeta
  ]
};

export const documentEditSelectLabelPrefixes = {
  documentType: 'INVOICE_EDIT_FORM_INVOICE_TYPE_',
  secondaryDocumentType: 'INVOICE_EDIT_FORM_SECONDARY_INVOICE_TYPE_',
  typeOfTransaction: 'INVOICE_EDIT_FORM_TYPE_OF_TRANSACTION_',
  paymentForm: 'INVOICE_EDIT_FORM_PAYMENT_FORM_'
};

export const invoiceFieldMapping = {
  documentType: 'INVOICE_TYPE',
  secondaryDocumentType: 'DOCUMENT_SECONDARY_DOCUMENT_TYPE',
  documentDate: 'INVOICE_DATE',
  period: 'INVOICE_PERIOD',
  documentNumber: 'INVOICE_NUMBER',
  dueDate: 'INVOICE_DUE_DATE',
  supplier: 'INVOICE_SUPPLIER',
  currency: 'INVOICE_CURRENCY',
  amount: 'INVOICE_AMOUNT',
  vatAmount: 'INVOICE_VAT_AMOUNT',
  vatPercentage: 'INVOICE_VAT_PERCENTAGE',
  discountAmount: 'INVOICE_DISCOUNT_AMOUNT',
  description: 'INVOICE_DESCRIPTION',
  paymentRefOGMVCS: 'INVOICE_PAYMENT_REF_OGMVCS',
  poReferenceNumber: 'INVOICE_PO_REFERENCE_NUMBER',
  receiverCompany: 'INVOICE_RECEIVER_COMPANY',
  receiverVatNumber: 'INVOICE_RECEIVER_VAT_NUMBER',
  receiverTaxNumber: 'INVOICE_RECEIVER_TAX_NUMBER',
  receiverAddress: 'INVOICE_RECEIVER_ADDRESS',
  receiverCity: 'INVOICE_RECEIVER_CITY',
  receiverPostalCode: 'INVOICE_RECEIVER_POSTAL_CODE',
  receiverStreet: 'INVOICE_RECEIVER_STREET',
  senderCompany: 'INVOICE_SENDER_COMPANY',
  senderVatNumber: 'INVOICE_SENDER_VAT_NUMBER',
  senderTaxNumber: 'INVOICE_SENDER_TAX_NUMBER',
  senderAddress: 'INVOICE_SENDER_ADDRESS',
  senderCity: 'INVOICE_SENDER_CITY',
  senderPostalCode: 'INVOICE_SENDER_POSTAL_CODE',
  senderStreet: 'INVOICE_SENDER_STREET',
  senderWebsite: 'INVOICE_SENDER_WEBSITE',
  senderEmail: 'INVOICE_SENDER_EMAIL',
  senderKvk: 'INVOICE_SENDER_KVK',
  senderIban: 'INVOICE_SENDER_IBAN',
  senderPhone: 'INVOICE_SENDER_PHONE',
  senderFax: 'INVOICE_SENDER_FAX',
  doNotPay: 'INVOICE_DO_NOT_PAY',
  fileName: 'INVOICE_FILE_NAME',
  referenceNumber: 'INVOICE_REFERENCE_NUMBER',
  ocrNumberSE: 'INVOICE_OCR_NUMBER_SE',
  clientCode: 'INVOICE_CLIENT_CODE',
  totalWeight: 'INVOICE_TOTAL_WEIGHT',
  packagesNumber: 'INVOICE_PACKAGES_NUMBER',
  subtotal: 'INVOICE_SUBTOTAL',
  bookingNumber: 'INVOICE_BOOKING_NUMBER',
  // poClientCode: 'PO_CLIENT_CODE',
  deliveryDate: 'PO_DELIVERY_DATE',
  discountRate: 'INVOICE_DISCOUNT_RATE',
  baseAmount: 'INVOICE_BASE_AMOUNT',
  paymentForm: 'INVOICE_PAYMENT_FORM',
  equivalenceSurchargeAmount: 'INVOICE_EQUIVALENCE_SURCHARGE_AMOUNT',
  equivalenceSurchargeRate: 'INVOICE_EQUIVALENCE_SURCHARGE_RATE',
  operationalOffice: 'INVOICE_OPERATIONAL_OFFICE',
  typeOfOperation: 'INVOICE_TYPE_OF_OPERATION',
  linkToDocument: 'LINK_TO_DOCUMENT',
  retentionAmount: 'INVOICE_RETENTION_AMOUNT',
  retentionPercentage: 'INVOICE_RETENTION_PERCENTAGE',
  terms: 'INVOICE_TERMS',
  gstAmount: 'INVOICE_GST_AMOUNT',
  pstAmount: 'INVOICE_PST_AMOUNT',
  deliveryType: 'INVOICE_DELIVERY_TYPE',
  name: 'DOCUMENT_NAME',
  surname: 'DOCUMENT_SURNAME',
  nationality: 'DOCUMENT_NATIONALITY',
  countryIssued: 'DOCUMENT_COUNTRY_ISSUED',
  dateOfBirth: 'DOCUMENT_DATE_OF_BIRTH',
  dateOfIssue: 'DOCUMENT_DATE_OF_ISSUE',
  dateOfExpiry: 'DOCUMENT_DATE_OF_EXPIRY',
  sex: 'DOCUMENT_SEX',
  placeOfIssue: 'DOCUMENT_PLACE_OF_ISSUE',
  mrz: 'DOCUMENT_MRZ',
  linkToDocumentPdf: 'LINK_TO_DOCUMENT_PDF',
  documentOwner: 'DOCUMENT_OWNER',
  companyCode: 'INTEGRATION_COMPANY_CODE',
  typeOfTransaction: 'DOCUMENT_TYPE_OF_TRANSACTION',
  archiveNumber: 'DOCUMENT_ARCHIVE_NUMBER',
  contactNumber: 'DOCUMENT_CONTATCT_NUMBER',
  estimationNumber: 'DOCUMENT_ESTIMATION_NUMBER',
  placeAndDateOfEstimation: 'DOCUMENT_PLACE_AND_DATE_OF_ESTIMATION',
  estimationPeriod: 'DOCUMENT_ESTIMATION_PERIOD',
  ccoste: 'DOCUMENT_CCOSTE',
  deliveryNoteNumber: 'DOCUMENT_DELIVERY_NOTE_NUMBER',
  dateOfIssue2: 'DOCUMENT_DATE_OF_ISSUE2',
  totalAmountBeforeDiscount: 'INVOICE_TOTAL_AMOUNT_BEFORE_DISCOUNT',
  referenceNumber2: 'INVOICE_REFERENCE_NUMBER2',
  contractNumber: 'INVOICE_CONTRACT_NUMBER',
  mnjBaseAmount: 'INVOICE_MNJ_BASE_AMOUNT',
  mnjDescription: 'INVOICE_MNJ_DESCRIPTION',
  senderContactName: 'INVOICE_SENDER_CONTACT_NAME',
  mnjPeriodStart: 'INVOICE_MNJ_PERIOD_START',
  mnjPeriodEnd: 'INVOICE_MNJ_PERIOD_END',
  mnjPlace: 'INVOICE_MNJ_PLACE',
  dossierNumber: 'INVOICE_DOSSIER_NUMBER',
};

export const invoiceLinesFieldMapping = {
  line: 'INVOICE_LINE_LINE',
  lineAmount: 'INVOICE_LINE_AMOUNT',
  linePrice: 'INVOICE_LINE_PRICE',
  lineUnitPrice: 'INVOICE_LINE_UNIT_PRICE',
  lineDiscount: 'INVOICE_LINE_DISCOUNT',
  lineQuantity: 'INVOICE_LINE_QUANTITY',
  lineVatrate: 'INVOICE_LINE_VAT_RATE',
  lineTax: 'INVOICE_LINE_VAT_AMOUNT',
  lineArticle: 'INVOICE_LINE_ARTICLE',
  lineReferenceNumber: 'INVOICE_LINE_LINE_REFERENCE_NUMBER',
  lineProductCount: 'INVOICE_LINE_LINE_PRODUCT_COUNT',
  lineUnitMeasurement: 'PO_LINE_UNIT_MEASUREMENT',
  lineDescription: 'INVOICE_LINE_DESCRIPTION',
  lineGLAccount: 'INVOICE_LINE_GLA',
  lineVatGroup: 'INVOICE_LINE_VAT_GROUP',
  lineCostCenter: 'INVOICE_LINE_CC',
  lineProject: 'INVOICE_LINE_PROJECT',
  lineArticleClient: 'INVOICE_LINE_ARTICLE_CLIENT',
  lineDiscountAmountOnUnitPrice: 'INVOICE_LINE_DISCOUNT_AMOUNT_ON_UNIT_PRICE',
  lineDiscountAmountOnTotalPrice: 'INVOICE_LINE_DISCOUNT_AMOUNT_ON_TOTAL_PRICE',
  lineDiscountedPrice: 'INVOICE_LINE_DISCOUNTED_PRICE',
  lineDeposit: 'INVOICE_LINE_DEPOSIT',
  lineExtraCost: 'INVOICE_LINE_EXTRA_COST',
  lineVatCode: 'INVOICE_LINE_VAT_CODE',
  lineBaseAmount: 'INVOICE_LINE_BASE_AMOUNT',
  lineRetentionPercentage: 'INVOICE_LINE_RETENTION_PERCENTAGE',
  lineRetentionAmount: 'INVOICE_LINE_RETENTION_AMOUNT',
  lineCategory: 'INVOICE_LINE_CATEGORY',
  lineProductCode: 'INVOICE_LINE_PRODUCT_CODE',
  lineTotalPV: 'INVOICE_LINE_TOTAL_PV',
  lineMeasureType: 'INVOICE_LINE_MEASURE_TYPE',
  lineDelRecCompName: 'INVOICE_LINE_DEL_REC_COMP_NAME',
  lineDelRecContName: 'INVOICE_LINE_DEL_REC_CONT_NAME',
  lineDelRecAddress: 'INVOICE_LINE_DEL_REC_ADDRESS',
  lineDelRecPhoneNumber: 'INVOICE_LINE_DEL_REC_PHONE_NUMBER',
  lineDelRecEmail: 'INVOICE_LINE_DEL_REC_EMAIL',
  lineDeliveryDate: 'INVOICE_LINE_DELIVERY_DATE_TIME',
  lineTeilnr: 'INVOICE_LINE_TEILNR',
  lineTeilnrDelivery: 'INVOICE_LINE_TEILNR_DELIVERY',
};

export const vatLineFieldMapping = {
  baseAmount: 'INVOICE_VAT_DETAIL_BASE_AMOUNT',
  vatRate: 'INVOICE_VAT_DETAIL_RATE',
  vatAmount: 'INVOICE_VAT_DETAIL_AMOUNT'
};

export const esLineFieldMapping = {
  baseAmount: 'INVOICE_ES_DETAIL_BASE_AMOUNT',
  esRate: 'INVOICE_ES_DETAIL_RATE',
  esAmount: 'INVOICE_ES_DETAIL_AMOUNT'
};

export const supplierFieldMapping = {
  creditorCode: 'SUPPLIER_CODE',
  creditorName: 'SUPPLIER_NAME',
  fiscalName: 'SUPPLIER_FISCAL_NAME',
  autoPaymentStatus: 'SUPPLIER_AUTO_PAYMENT_STATUS',
  dueDays: 'SUPPLIER_DUE_DAYS',
  website: 'SUPPLIER_WEBSITE',
  comment: 'SUPPLIER_COMMENT',
  companyRegNumber: 'SUPPLIER_COMPANY_REG_NUMBER',
  vatNumber: 'SUPPLIER_VAT_NUMBER',
  referenceCode: 'SUPPLIER_REFERENCE_CODE',
  status: 'SUPPLIER_STATUS',
  generalLedgerAccount: 'SUPPLIER_GENERAL_LEDGER_ACCOUNT',
  vatGroup: 'SUPPLIER_VAT_GROUP',
  generalLedgerAccLine: 'SUPPLIER_GENERAL_LEDGER_ACC_LINE',
  vatGroupLine: 'SUPPLIER_VAT_GROUP_LINE',
  invoiceDescriptionRule: 'SUPPLIER_INVOICE_DESCRIPTION_RULE',
  invoiceLineDescriptionRule: 'SUPPLIER_INVOICE_LINE_DESCRIPTION_RULE',
  defaultCurrency: 'SUPPLIER_DEFAULT_CURRENCY',
  bankCountry: 'SUPPLIER_BANK_COUNTRY',
  ascription: 'SUPPLIER_ASCRIPTION',
  iban: 'SUPPLIER_IBAN',
  bankAccNumber: 'SUPPLIER_BANK_ACC_NUMBER',
  bicSwift: 'SUPPLIER_BIC_SWIFT',
  nationalBankCode: 'SUPPLIER_NATIONAL_BANK_CODE',
  bankName: 'SUPPLIER_BANK_NAME',
  bankCity: 'SUPPLIER_BANK_CITY',
  bankStreet: 'SUPPLIER_BANK_STREET',
  bankHouseNumber: 'SUPPLIER_BANK_HOUSE_NUMBER',
  country: 'SUPPLIER_COUNTRY',
  city: 'SUPPLIER_CITY',
  postalCode: 'SUPPLIER_POSTAL_CODE',
  address1: 'SUPPLIER_ADDRESS_1',
  address2: 'SUPPLIER_ADDRESS_2',
  poBox: 'SUPPLIER_PO_BOX',
  poBoxPostalCode: 'SUPPLIER_PO_BOX_POSTAL_CODE',
  poBoxCity: 'SUPPLIER_PO_BOX_CITY',
  contactPersonName: 'SUPPLIER_CONTACT_PERSON_NAME',
  telephoneNumber: 'SUPPLIER_TELEPHONE_NUMBER',
  faxNumber: 'SUPPLIER_FAX_NUMBER',
  mobileTelNumber: 'SUPPLIER_MOBILE_TEL_NUMBER',
  emailAddress: 'SUPPLIER_EMAIL_ADDRESS',
  postcode: 'SUPPLIER_POSTCODE',
  state: 'SUPPLIER_STATE',
  discountDays: 'SUPPLIER_DISCOUNT_DAYS',
  discountPercentage: 'SUPPLIER_DISCOUNT_PERCENTAGE',
  matchType: 'SUPPLIER_MATCH_TYPE',
  custom1: 'SUPPLIER_CUSTOM_1',
  custom2: 'SUPPLIER_CUSTOM_2',
  custom3: 'SUPPLIER_CUSTOM_3'
};

export const zertifikaParentCompanyID = '641415243';
export const docunectaParentCompanyID = '989017919';

export const annotationSpecialCharacters = [',', '.', '/', '-'];

export const exportMappingEntities = [
  'header',
  'supplier',
  'line',
  'vat',
  'es'
];

export const vatLineFields = [
  {
    key: 'baseAmount',
    label: vatLineLabels.baseAmount,
    isAvailable: true
  },
  {
    key: 'vatRate',
    label: vatLineLabels.vatRate,
    isAvailable: true
  },
  {
    key: 'vatAmount',
    label: vatLineLabels.vatAmount,
    isAvailable: true
  }
];

export const esLineFields = [
  {
    key: 'baseAmount',
    label: esLineLabels.baseAmount,
    isAvailable: true
  },
  {
    key: 'esRate',
    label: esLineLabels.esRate,
    isAvailable: true
  },
  {
    key: 'esAmount',
    label: esLineLabels.esAmount,
    isAvailable: true
  }
];

export const dataFormatLabels = {
  csv: 'CSV',
  excel: 'Excel',
  dat: 'DAT'
};

export const projects = [
  {
    key: 'invoices_milk_CSV',
    label: 'PROJECT_INVOICES_MILK_CSV'
  },
  {
    key: 'invoices_hotels_XML',
    label: 'PROJECT_INVOICES_HOTELS_XML'
  },
  {
    key: 'invoices_hotels_API',
    label: 'PROJECT_INVOICES_HOTELS_API'
  },
  {
    key: 'po_restaurants_gstock',
    label: 'PROJECT_PO_RESTAURANTS_GSTOCK'
  },
  {
    key: 'po_beverages_navision',
    label: 'PROJECT_PO_BEVERAGES_NAVISION'
  },
  {
    key: 'passports_ids_eu',
    label: 'PROJECT_PASSPORTS_IDS_EU'
  },
  {
    key: 'passports_ids_lesotho',
    label: 'PROJECT_PASSPORTS_IDS_LESOTHO'
  }
];

export const headerFieldIds = [
  'documentDate',
  'period',
  'documentNumber',
  'dueDate',
  'supplier',
  'currency',
  'amount',
  'vatAmount',
  'discountAmount',
  'description',
  'vatPercentage',
  'paymentRefOGMVCS',
  'poReferenceNumber',
  'receiverCompany',
  'receiverVatNumber',
  'receiverAddress',
  'receiverCity',
  'receiverStreet',
  'receiverPostalCode',
  'receiverTaxNumber',
  'senderCompany',
  'senderVatNumber',
  'senderAddress',
  'senderCity',
  'senderStreet',
  'senderPostalCode',
  'senderWebsite',
  'senderEmail',
  'senderKvk',
  'senderIban',
  'senderPhone',
  'senderFax',
  'senderTaxNumber',
  'referenceNumber',
  'ocrNumberSE',
  'vatAmount2',
  'vatPercentage2',
  'clientCode',
  'totalWeight',
  'packagesNumber',
  'subtotal',
  'bookingNumber',
  'deliveryDate',
  'baseAmount',
  'discountRate',
  'equivalenceSurchargeAmount',
  'equivalenceSurchargeRate',
  'operationalOffice',
  'typeOfOperation',
  'retentionAmount',
  'retentionPercentage',
  'terms',
  'gstAmount',
  'pstAmount',
  'deliveryType',
  'archiveNumber',
  'contactNumber',
  'estimationNumber',
  'placeAndDateOfEstimation',
  'estimationPeriod',
  'ccoste',
  'deliveryNoteNumber',
  'dateOfIssue2',
  'totalAmountBeforeDiscount',
  'referenceNumber2',
  'contractNumber',
  'mnjBaseAmount',
  'mnjDescription',
  'senderContactName',
  'mnjPeriodStart',
  'mnjPeriodEnd',
  'mnjPlace',
  'dossierNumber',
  'name',
  'surname',
  'nationality',
  'countryIssued',
  'dateOfBirth',
  'dateOfIssue',
  'dateOfExpiry',
  'sex',
  'placeOfIssue',
  'mrz'
];

export const lineFieldIds = [
  'lineDescription',
  'lineArticle',
  'lineQuantity',
  'lineUnitPrice',
  'lineDiscount',
  'lineDiscountedPrice',
  'lineDiscountAmountOnTotalPrice',
  'lineVatrate',
  'lineTax',
  'lineAmount',
  'lineGLAccount',
  'lineVatGroup',
  'lineCostCenter',
  'lineProject',
  'lineProductCount',
  'linePrice',
  'lineArticleClient',
  'lineDiscountAmountOnUnitPrice',
  'lineVatcode',
  'lineDeposit',
  'lineExtraCost',
  'lineReferenceNumber',
  'lineUnitMeasurement',
  'lineBaseAmount',
  'lineRetentionAmount',
  'lineRetentionPercentage',
  'lineCategory',
  'lineProductCode',
  'lineTotalPV',
  'lineMeasureType',
  'lineDelRecCompName',
  'lineDelRecContName',
  'lineDelRecAddress',
  'lineDelRecPhoneNumber',
  'lineDelRecEmail',
  'lineDeliveryDate',
  'lineTeilnr',
  'lineTeilnrDelivery'
];
